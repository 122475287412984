import React from "react";
import './LipBalm.css'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import cardImg1 from '../Assests/Images/custom-lipbalm-boxes1.png'
import formImg1 from '../Assests/Images/Competitive-Pricing.png'
import formImg2 from '../Assests/Images/Starting-from-100-boxes.png'
import formImg3 from '../Assests/Images/High-quality-offset-printing.png'
import formImg4 from '../Assests/Images/Custom-Sizes-&-Style.png'
import formImg5 from '../Assests/Images/15-business-day-turnaround.png'
import formImg6 from '../Assests/Images/No-die-&-plate-charge.png'
import ProductSpecification from "../components/ProductSpecification";


function LipBalm() {
    return(
        <div className="row">
            <div className="col-md-4 imgDiv">
              <img class="card-img-top" src={cardImg1} alt="Card cap"/>
            </div>
            <div className="col-md-4">
                    <h5> Custom Printed Cardboard Tincture Boxes</h5>
                    <p className="paragraphJustify"> Challenge us with your thoughts to attain custom in design and printed cardboard stuff boxes can be opted in gable shape with technique and designs of your variety. Free designing services are provided with superb digital printing services. Custom-made boxes produced from material cardboard entirely packed cosmetic and food. Hence, the beautifully customized Retail and Wholesale Boxes is a perfect pick for your products collection.</p>
                    <hr/>
                    <h3 className="redHeading"> Get Custom Quote </h3>


                    <div className="formDiv">
                        <form>
            <div className="form-row">
                <div className="form-group col-md-3">
                    <input type="number" class="form-control" id="lenght" placeholder="Lenght"/>
                </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="width" placeholder="width"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="depth" placeholder="depth"/>
            </div>
            <div className="form-group col-md-3">
                <select id="inputInches" class="form-control" placeholder="Inch">
                    <option >cm</option>
                    <option>mm</option>
                    <option>inch</option>
                </select>
            </div>
        </div>
        <div className="form-group">
        <label for="inputAddress2">Product Name</label>
        <select id="inputInches" class="form-control">
                    <option>Auto-lock Boxes</option>
                    <option>Baby product boxes</option>
                    <option>Bakery Boxes</option>
                    <option>Ballot Boxes</option>
                    <option>Bandage Boxes</option>
                    <option>Belt Boxes</option>
                    <option>Black Gable Boxes</option>
                    <option>Book end Boxes</option>
                    <option>Bottle Boxes</option>
                    <option>Bottle Neckers</option>
                    <option>Buisness Card Boxes</option>
                    <option>Cake Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Candy Apple Boxes</option>
                    <option>Candy Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Cardboard Boxes</option>
                    <option>Cardboard Dispenser Boxes</option>
                    <option>Cereal Boxes</option>
                    <option>Choclate Boxes</option>
                    <option>Christmas Boxes</option>
                    <option>Ciggarette Boxes</option>
                    <option>Cookie Boxes</option>
                    <option>Corrugated Boxes</option>
                    <option>Cosmetic Boxes</option>
                    <option>Cosmetic Display Boxes</option>
                    <option>Cream Boxes</option>
                    <option>Cube Boxes</option>
                    <option>Cupcake Boxes</option>
                    <option>Custom Bath Bomb Boxes</option>
                    <option>Custom Electronic Ciggarette Boxes</option>
                    <option>Display Boxes</option>
                    <option>Donut Boxes</option>
                    <option>E-Liquid Boxes</option>
                    <option>Eye Shadow Boxes</option>
                    <option>Eye Liner Boxes</option>
                    <option>Favour Boxes</option>
                    <option>Flap Boxes</option>
                    <option>Foundation Boxes</option>
                    <option>Gable Boxes</option>
                    <option>Game Boxes</option>
                    <option>Gift Boxes</option>
                    <option>Gift Card Boxes</option>
                    <option>Gold Foil Boxes</option>
                    <option>Hair Extension Boxes</option>
                    <option>Hair Spray Boxes</option>
                    <option>Handle Boxes</option>
                    <option>Hanging Tab Boxes</option>
                    <option>Hexagon Boxes</option>
                    <option>Invitation Boxes</option>
                    <option>Kraft Boxes</option>
                    <option>Lip Gloss Boxes</option>
                    <option>lipstick Boxes</option>
                    <option>Lotion Boxes</option>
                    <option>Macaron Boxes</option>
                    <option>Mailer Boxes</option>
                    <option>Makeup Boxes</option>
                    <option>Mascara Boxes</option>
                    <option>Medicine Boxes</option>
                    <option>Muffin Boxes</option>
                    <option>Nail Polish Boxes</option>
                    <option>Ornament Boxes</option>
                    <option>Paper Boxes</option>
                    <option>Paper Cup Boxes</option>
                    <option>Pastry Boxes</option>
                    <option>Perfume Boxes</option>
                    <option>Pie Boxes</option>
                    <option>Pillow Boxes</option>
                    <option>Pizza Boxes</option>
                    <option>Playing Card Boxes</option>
                    <option>Popcorn Boxes</option>
                    <option>Product Boxes</option>
                    <option>Pyramid Boxes</option>
                    <option>Retail Boxes</option>
                    <option>Rigid Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Snack Boxes</option>
                    <option>Soap Boxes</option>
                    <option>Software Boxes</option>
                    <option>Tea Boxes</option>
                    <option>Tie Boxes</option>
                    <option>Toy Boxes</option>
                    <option>USB Boxes</option>
                    <option>Window Boxes</option>
                </select>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
            <label for="inputColor">Color:</label>
            <select id="inputState" class="form-control">
                <option selected>1 Color </option>
                <option>2 Color</option>
                <option>3 Color</option>
                <option>4 Color</option>
                <option>4/1 Color</option>
                <option>4/2 Color</option>
                <option>4/3 Color</option>
                <option>4/4 Color</option>
            </select>
        </div>
    <div class="form-group col-md-6">
        <label for="inputQuantity">Quantity</label>
        <input type="number" class="form-control" id="quantity"/>
    </div>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="Name"/>
    </div>
    <div className="form-row formRow">
    <input type="email" class="form-control" placeholder="Email"/>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="tel"/>
    </div>
        <button type="submit" class="btn btn-primary formBtn">Submit</button>
                        </form>
                        {/* this is the findal div */}
                    </div>
            </div>
            <div className="col-md-4">
                <div className="row">
                    <div className="col-md-6">
                        <img class="card-img-top" src={formImg6} alt="Card cap"/>
                        <span className="textAlign"> No Die and Plate Charges </span>
                        <br/>
                         <br/>
                    </div>
                     <div className="col-md-6">
                        <img class="card-img-top" src={formImg3} alt="Card cap"/>
                        <span className="textAlign"> Quick Turn Around time </span>
                        <br/>
                        <br/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                         <img class="card-img-top" src={formImg5} alt="Card cap"/>
                        <span className="textAlign"> Free Shipping Across USA </span>
                        <br/>
                        <br/>
                    </div>
                    <div className="col-md-6">
                        <img class="card-img-top" src={formImg2} alt="Card cap"/>
                        <span className="textAlign"> No Minimum Order required </span>
                        <br/>
                        <br/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <img class="card-img-top" src={formImg1} alt="Card cap"/>
                        <span className="textAlign"> Free Graphic Designing </span>
                        <br/>
                        <br/>
                    </div>
                    <div className="col-md-6">
                        <img class="card-img-top" src={formImg4} alt="Card cap"/>
                        <span className="textAlign"> Custom Size & Style </span>
                        <br/>
                        <br/>
                    </div>
                </div>
                
            </div>   
            
                <ProductSpecification>
                    
                </ProductSpecification>

               
           
        </div>
    )
};

export default LipBalm;