import React from "react";
import "./HealthBoxes.css";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import box1 from '../Assests/Images/HealthBoxes/beard-oil-boxes.png'
import box2 from '../Assests/Images/HealthBoxes/custom-dust-mask-box1.png'
import box3 from '../Assests/Images/HealthBoxes/custom-essential-oil-box.png'
import box4 from '../Assests/Images/HealthBoxes/custom-sanitizer-boxes.png'
import box5 from '../Assests/Images/HealthBoxes/custom-sanitizer-boxes.png'
import box6 from '../Assests/Images/HealthBoxes/custom-surgical-face-mask-boxes-oxo1.png'
import box7 from '../Assests/Images/HealthBoxes/eyedrops2.png'
import box8 from '../Assests/Images/HealthBoxes/Medicine_Boxes1.png'

// import box9 from '../Assests/Images/HealthBoxes/custom-makeup-boxes-wholesale1.png'
// import box10 from '../Assests/Images/HealthBoxes/custom-nailpoilsh-boxes.png'
// import box11 from '../Assests/Images/HealthBoxes/custom-sanitizer-boxes.png'
// import box12 from '../Assests/Images/HealthBoxes/custom-sun-protection-cream-box.png'
// import box13 from '../Assests/Images/CosmeticBoxes/general-copsmetic-box.png'
// import box14 from '../Assests/Images/CosmeticBoxes/glossylotion2.png'
// import box15 from '../Assests/Images/CosmeticBoxes/hair-extension-boxes.png'
// import box16 from '../Assests/Images/CosmeticBoxes/hair-spray-box-2nd.png'
// import box17 from '../Assests/Images/CosmeticBoxes/kraft-lipstick-box.png'
// import box18 from '../Assests/Images/CosmeticBoxes/mascara-box-main-2.png'
// import box19 from '../Assests/Images/CosmeticBoxes/perfume2.png'
// import box20 from '../Assests/Images/CosmeticBoxes/unique-cosmetoic-box.png'
// import box21 from '../Assests/Images/CosmeticBoxes/Wax_Stripes-Boxes.png'

import bannerImg from '../Assests/Images/final-gif.gif';

const HealthBoxes=() =>{
    return(
        <div className="mainDiv">
        <div className="jumbotron">
        <h3> Custom Cosmetic Boxes </h3>
        <img class="card-img-top" src={bannerImg} alt="Card cap"/>
        </div>
    
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box1} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Printed Beard Oil Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box2} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Dust Mask Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box3} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Custom Essential Oil Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card formCard" style={{width: '18rem'}}>
    <div> <h4 className="customQuoteheading"> Get Custom Quote </h4> </div>
    <div className="card-body formCard">
    <form>
        <div className="form-row">
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="lenght" placeholder="Lenght"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="width" placeholder="width"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="depth" placeholder="depth"/>
            </div>
            <div className="form-group col-md-3">
                <select id="inputInches" class="form-control" placeholder="Inch">
                    <option >cm</option>
                    <option>mm</option>
                    <option>inch</option>
                </select>
            </div>
        </div>
        <div className="form-group">
        <label for="inputAddress2">Product Name</label>
        <select id="inputInches" class="form-control">
                    <option>Auto-lock Boxes</option>
                    <option>Baby product boxes</option>
                    <option>Bakery Boxes</option>
                    <option>Ballot Boxes</option>
                    <option>Bandage Boxes</option>
                    <option>Belt Boxes</option>
                    <option>Black Gable Boxes</option>
                    <option>Book end Boxes</option>
                    <option>Bottle Boxes</option>
                    <option>Bottle Neckers</option>
                    <option>Buisness Card Boxes</option>
                    <option>Cake Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Candy Apple Boxes</option>
                    <option>Candy Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Cardboard Boxes</option>
                    <option>Cardboard Dispenser Boxes</option>
                    <option>Cereal Boxes</option>
                    <option>Choclate Boxes</option>
                    <option>Christmas Boxes</option>
                    <option>Ciggarette Boxes</option>
                    <option>Cookie Boxes</option>
                    <option>Corrugated Boxes</option>
                    <option>Cosmetic Boxes</option>
                    <option>Cosmetic Display Boxes</option>
                    <option>Cream Boxes</option>
                    <option>Cube Boxes</option>
                    <option>Cupcake Boxes</option>
                    <option>Custom Bath Bomb Boxes</option>
                    <option>Custom Electronic Ciggarette Boxes</option>
                    <option>Display Boxes</option>
                    <option>Donut Boxes</option>
                    <option>E-Liquid Boxes</option>
                    <option>Eye Shadow Boxes</option>
                    <option>Eye Liner Boxes</option>
                    <option>Favour Boxes</option>
                    <option>Flap Boxes</option>
                    <option>Foundation Boxes</option>
                    <option>Gable Boxes</option>
                    <option>Game Boxes</option>
                    <option>Gift Boxes</option>
                    <option>Gift Card Boxes</option>
                    <option>Gold Foil Boxes</option>
                    <option>Hair Extension Boxes</option>
                    <option>Hair Spray Boxes</option>
                    <option>Handle Boxes</option>
                    <option>Hanging Tab Boxes</option>
                    <option>Hexagon Boxes</option>
                    <option>Invitation Boxes</option>
                    <option>Kraft Boxes</option>
                    <option>Lip Gloss Boxes</option>
                    <option>lipstick Boxes</option>
                    <option>Lotion Boxes</option>
                    <option>Macaron Boxes</option>
                    <option>Mailer Boxes</option>
                    <option>Makeup Boxes</option>
                    <option>Mascara Boxes</option>
                    <option>Medicine Boxes</option>
                    <option>Muffin Boxes</option>
                    <option>Nail Polish Boxes</option>
                    <option>Ornament Boxes</option>
                    <option>Paper Boxes</option>
                    <option>Paper Cup Boxes</option>
                    <option>Pastry Boxes</option>
                    <option>Perfume Boxes</option>
                    <option>Pie Boxes</option>
                    <option>Pillow Boxes</option>
                    <option>Pizza Boxes</option>
                    <option>Playing Card Boxes</option>
                    <option>Popcorn Boxes</option>
                    <option>Product Boxes</option>
                    <option>Pyramid Boxes</option>
                    <option>Retail Boxes</option>
                    <option>Rigid Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Snack Boxes</option>
                    <option>Soap Boxes</option>
                    <option>Software Boxes</option>
                    <option>Tea Boxes</option>
                    <option>Tie Boxes</option>
                    <option>Toy Boxes</option>
                    <option>USB Boxes</option>
                    <option>Window Boxes</option>
                </select>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
            <label for="inputColor">Color:</label>
            <select id="inputState" class="form-control">
                <option selected>1 Color </option>
                <option>2 Color</option>
                <option>3 Color</option>
                <option>4 Color</option>
                <option>4/1 Color</option>
                <option>4/2 Color</option>
                <option>4/3 Color</option>
                <option>4/4 Color</option>
            </select>
        </div>
    <div class="form-group col-md-6">
        <label for="inputQuantity">Quantity</label>
        <input type="number" class="form-control" id="quantity"/>
    </div>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="Name"/>
    </div>
    <div className="form-row formRow">
    <input type="email" class="form-control" placeholder="Email"/>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="tel"/>
    </div>
        <button type="submit" class="btn btn-primary formBtn">Submit</button>
    </form>
        <hr/>
        </div>
    </div>
    </div>
    </div>
    {/* Second Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box4} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Sanitizer Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box5} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Condom Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box6} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Surgical Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Third ROw */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box7} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Eyedrop Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box8} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Medicine Boxes </h5>
    </div>
    </div>
    </div>
    {/* <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box9} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Makeup Boxes </h5>
    </div>
    </div>
    </div> */}
    </div>
    {/* Fourth Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box10} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Makeup Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box11} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  New NailPolish Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box12} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Sanitizer Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Fifth Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box13} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Sunspot Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box14} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  General Cosmetic Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box15} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Glossy lotion Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Sixth Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box16} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Hair Extension Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box17} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Hair Spray Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box18} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Lipstick Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Seventh Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box19} alt="Card cap"/>
    <div class="card-body">
    <h5> Mascara Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box20} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Perfume Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box21} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Unique Cosmetic Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="secondHeading"> Health Boxes</h3>
    <p className="secondPara"> We facilitate your business by bringing you innovative printed boxes with extraordinary design printing. </p>
    <div className="jumbotron jumboBackground">
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Custom Health Packaging
    </h3>
    <p className="paragraphText">
    Health has half of its heart in hygiene and tidiness. Your product can be thrown back at the wall if its packaging seems inappropriate and substandard. It’s not really difficult to keep going with good brand name and publicity as simple and clean impression glistens more than all. We don’t only make hygienic Health Boxes Packages but also aesthetic material to retain your consumers’ choices for you. Our Custom Health Packaging Boxes are ready to bring the smile of content on your face anytime you want. Custom Box City not only excels at production and printing of world-class shipping boxes but we also deliver finest quality of Bandage Boxes, Research Diagnostic Boxes, Tower Bandage Boxes and Cold Medicine Boxes that can be delivered at your doorstep within 4-8 business days.  
    </p>
    </div>
    </div>
    {/* Second Para */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Printed Custom Health Boxes for Neutral, Wholesome Product Representation
    </h3>
    <p className="paragraphText">
    Custom Health Packaging Boxes requires specialized packaging boxes that will provide the buyer with all the relevant information about the product. Why? Because any error in custom health boxes packaging could endanger the patient who is using the product. Not to mention the lawsuits a company can face. 
    As the number of health threats and diseases are increasing, so is the number of health boxes. Careful forethought and design layout is required for custom heath boxes if you are to make customers feel as if they can rely on your brand. A medicine or healthcare brand needs a pristine reputation if they wish to earn their place in highly competitive pharmaceutical market.
    </p>
    </div>
    </div>
    {/* Third paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Our Health Boxes Product Range:
    </h3>
    <p className="paragraphText" >
    Our custom health boxes range offers you not just Medicine Packaging Boxes but you can also order custom essential oil boxes and Custom Printed Eye Drops Packaging Boxes for your product range. Our healthcare boxes are produced under strict hygienic conditions that your industry requires. Our experts at Custom Box City Packaging appreciate and excels at the unique art of designing and printing custom health boxes. We assure you that these boxes can also within stand wear n tear damage that can occur during transportation to a certain degree.
    </p>
    </div>
    </div>
    {/* Fourth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Flawless Customer Care; From Our Production Unit to Your Doorstep:
    </h3>
    <p className="paragraphText">
    All orders at Custom Box City are manually sorted for any flaws or errors, pre-shipping. One our quality assurance department clear the boxes we send them to our clients in perfect flattened forms. Not only we excel at production and printing of world-class shipping boxes but we also deliver finest quality of Bandage Boxes, Research Diagnostic Boxes, Tower Bandage Boxes and Cold Medicine Boxes delivered at your doorstep within 4-8 business days. We charge no shipping fees for order deliveries. If you are happy with our custom health boxes manufacturing services, that’s our most cherished reward.
    </p>
    </div>
    </div>
    {/* Fifth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Free Doorstep Delivery!
    </h3>
    <p className="paragraphText">
    Now you just need to make a call (510) 500 9533 or email us at Sales@customboxcity.com. Do not forget to follow us on social media as it is always helping to keep in touch with your packaging partner regarding new announcements!  We will deliver the custom cardboard  boxes at doorstep without any extra shipping charges. 
    </p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    )
}

export default HealthBoxes;