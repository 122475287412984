import React from "react";
import "./CandleBox.css";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import box1 from '../Assests/Images/Candlebox/candle-display-packaging-boxes-356-2.png'
import box2 from '../Assests/Images/Candlebox/pyramid_boxes1.png'
import box3 from '../Assests/Images/Candlebox/Untitled-3sd.png'
import box4 from '../Assests/Images/Candlebox/Untitled-33d.png'

// import box5 from '../Assests/Images/Candlebox/custom-founation-boxes.png'
// import box6 from '../Assests/Images/Candlebox/custom-lipbalm-boxes1.png'
// import box7 from '../Assests/Images/CosmeticBoxes/custom-lip-balm-display-boxes.png'
// import box8 from '../Assests/Images/CosmeticBoxes/custom-lipstick-box-packaging.png'
// import box9 from '../Assests/Images/CosmeticBoxes/custom-makeup-boxes-wholesale1.png'
// import box10 from '../Assests/Images/CosmeticBoxes/custom-nailpoilsh-boxes.png'
// import box11 from '../Assests/Images/CosmeticBoxes/custom-sanitizer-boxes.png'
// import box12 from '../Assests/Images/CosmeticBoxes/custom-sun-protection-cream-box.png'
// import box13 from '../Assests/Images/CosmeticBoxes/general-copsmetic-box.png'
// import box14 from '../Assests/Images/CosmeticBoxes/glossylotion2.png'
// import box15 from '../Assests/Images/CosmeticBoxes/hair-extension-boxes.png'
// import box16 from '../Assests/Images/CosmeticBoxes/hair-spray-box-2nd.png'
// import box17 from '../Assests/Images/CosmeticBoxes/kraft-lipstick-box.png'
// import box18 from '../Assests/Images/CosmeticBoxes/mascara-box-main-2.png'
// import box19 from '../Assests/Images/CosmeticBoxes/perfume2.png'
// import box20 from '../Assests/Images/CosmeticBoxes/unique-cosmetoic-box.png'
// import box21 from '../Assests/Images/CosmeticBoxes/Wax_Stripes-Boxes.png'

import bannerImg from '../Assests/Images/final-gif.gif';

const CandleBox=() =>{
    return(
        <div className="mainDiv">
        <div className="jumbotron">
        <h3> Custom Candle Boxes </h3>
        <img class="card-img-top" src={bannerImg} alt="Card cap"/>
        </div>
    
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box1} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Candle Display Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box2} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Pyramid Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box3} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Custom Jar Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card formCard" style={{width: '18rem'}}>
    <div> <h4 className="customQuoteheading"> Get Custom Quote </h4> </div>
    <div className="card-body formCard">
    <form>
        <div className="form-row">
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="lenght" placeholder="Lenght"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="width" placeholder="width"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="depth" placeholder="depth"/>
            </div>
            <div className="form-group col-md-3">
                <select id="inputInches" class="form-control" placeholder="Inch">
                    <option >cm</option>
                    <option>mm</option>
                    <option>inch</option>
                </select>
            </div>
        </div>
        <div className="form-group">
        <label for="inputAddress2">Product Name</label>
        <select id="inputInches" class="form-control">
                    <option>Auto-lock Boxes</option>
                    <option>Baby product boxes</option>
                    <option>Bakery Boxes</option>
                    <option>Ballot Boxes</option>
                    <option>Bandage Boxes</option>
                    <option>Belt Boxes</option>
                    <option>Black Gable Boxes</option>
                    <option>Book end Boxes</option>
                    <option>Bottle Boxes</option>
                    <option>Bottle Neckers</option>
                    <option>Buisness Card Boxes</option>
                    <option>Cake Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Candy Apple Boxes</option>
                    <option>Candy Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Cardboard Boxes</option>
                    <option>Cardboard Dispenser Boxes</option>
                    <option>Cereal Boxes</option>
                    <option>Choclate Boxes</option>
                    <option>Christmas Boxes</option>
                    <option>Ciggarette Boxes</option>
                    <option>Cookie Boxes</option>
                    <option>Corrugated Boxes</option>
                    <option>Cosmetic Boxes</option>
                    <option>Cosmetic Display Boxes</option>
                    <option>Cream Boxes</option>
                    <option>Cube Boxes</option>
                    <option>Cupcake Boxes</option>
                    <option>Custom Bath Bomb Boxes</option>
                    <option>Custom Electronic Ciggarette Boxes</option>
                    <option>Display Boxes</option>
                    <option>Donut Boxes</option>
                    <option>E-Liquid Boxes</option>
                    <option>Eye Shadow Boxes</option>
                    <option>Eye Liner Boxes</option>
                    <option>Favour Boxes</option>
                    <option>Flap Boxes</option>
                    <option>Foundation Boxes</option>
                    <option>Gable Boxes</option>
                    <option>Game Boxes</option>
                    <option>Gift Boxes</option>
                    <option>Gift Card Boxes</option>
                    <option>Gold Foil Boxes</option>
                    <option>Hair Extension Boxes</option>
                    <option>Hair Spray Boxes</option>
                    <option>Handle Boxes</option>
                    <option>Hanging Tab Boxes</option>
                    <option>Hexagon Boxes</option>
                    <option>Invitation Boxes</option>
                    <option>Kraft Boxes</option>
                    <option>Lip Gloss Boxes</option>
                    <option>lipstick Boxes</option>
                    <option>Lotion Boxes</option>
                    <option>Macaron Boxes</option>
                    <option>Mailer Boxes</option>
                    <option>Makeup Boxes</option>
                    <option>Mascara Boxes</option>
                    <option>Medicine Boxes</option>
                    <option>Muffin Boxes</option>
                    <option>Nail Polish Boxes</option>
                    <option>Ornament Boxes</option>
                    <option>Paper Boxes</option>
                    <option>Paper Cup Boxes</option>
                    <option>Pastry Boxes</option>
                    <option>Perfume Boxes</option>
                    <option>Pie Boxes</option>
                    <option>Pillow Boxes</option>
                    <option>Pizza Boxes</option>
                    <option>Playing Card Boxes</option>
                    <option>Popcorn Boxes</option>
                    <option>Product Boxes</option>
                    <option>Pyramid Boxes</option>
                    <option>Retail Boxes</option>
                    <option>Rigid Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Snack Boxes</option>
                    <option>Soap Boxes</option>
                    <option>Software Boxes</option>
                    <option>Tea Boxes</option>
                    <option>Tie Boxes</option>
                    <option>Toy Boxes</option>
                    <option>USB Boxes</option>
                    <option>Window Boxes</option>
                </select>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
            <label for="inputColor">Color:</label>
            <select id="inputState" class="form-control">
                <option selected>1 Color </option>
                <option>2 Color</option>
                <option>3 Color</option>
                <option>4 Color</option>
                <option>4/1 Color</option>
                <option>4/2 Color</option>
                <option>4/3 Color</option>
                <option>4/4 Color</option>
            </select>
        </div>
    <div class="form-group col-md-6">
        <label for="inputQuantity">Quantity</label>
        <input type="number" class="form-control" id="quantity"/>
    </div>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="Name"/>
    </div>
    <div className="form-row formRow">
    <input type="email" class="form-control" placeholder="Email"/>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="tel"/>
    </div>
        <button type="submit" class="btn btn-primary formBtn">Submit</button>
    </form>
        <hr/>
        </div>
    </div>
    </div>
    </div>
    {/* Second Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box4} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Pyramid Boxes </h5>
    </div>
    </div>
    </div>
    {/* <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box5} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Foundation Boxes </h5>
    </div>
    </div>
    </div> */}
    {/* <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box6} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Lipbalm Boxes </h5>
    </div>
    </div>
    </div> */}
    </div>
    {/* Third ROw */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box7} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Lipbalm Display Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box8} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Lipstick Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box9} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Makeup Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Fourth Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box10} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Makeup Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box11} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  New NailPolish Boxes </h5>
    </div>
    </div>
    </div> */}
    {/* <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box12} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Sanitizer Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Fifth Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box13} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Sunspot Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box14} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  General Cosmetic Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box15} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Glossy lotion Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Sixth Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box16} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Hair Extension Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box17} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Hair Spray Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box18} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Lipstick Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Seventh Row */}
     {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box19} alt="Card cap"/>
    <div class="card-body">
    <h5> Mascara Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box20} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Perfume Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box21} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Unique Cosmetic Boxes </h5>
    </div> 
    </div>
    </div>
    </div> */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="secondHeading"> Custom Candle Boxes</h3>
    <p className="secondPara"> We facilitate your business by bringing you innovative printed boxes with extraordinary design printing. </p>
    <div className="jumbotron jumboBackground">
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Enlighten the product with artistic candle boxes wholesale
    </h3>
    <p className="paragraphText">
    TIt is mandatory to enlighten the candles, votive, and lights packaging as the product serves the customers by lighting up the atmosphere. Custom box City creates the candle boxes wholesale at an affordable rate with the artistic style and creative artwork which contributes to the outlook. The staff helps the business present the product in an alluring way which escalates the sales. The logo is embossed on the Custom Candle Boxes for making the distinctive mark of the company prominent. The skilled resources make the candle packaging wholesale ready for the marketing, it eliminates the requirement of customer support staff at the store for the promoting purpose. The businessman is free to share the idea with the expert designers, they get it and polish it to produce excellent packaging. The well-crafted packaging creates the BRAND name in the market and helps in BRAND recognition at first glance.
    </p>
    </div>
    </div>
    {/* Second Para */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Liven up your branding with our Candle Packaging Boxes Wholesale
    </h3>
    <p className="paragraphText">
    Box Packaging that grabs the attention of customers, our specialized designers can create such Custom Candle Boxes which are accessible in different sizes and shapes, with your personalized product to impress your customers. People love to perceive attractive packaging and at the platform of Custom box City will be delivering such modified Candle Boxes Wholesale that catch buyer’s attention. Perfectly crafted boxes which our professional designers can design for you, Also are offered in various dimensions and forms with your distinguish branding.
    </p>
    </div>
    </div>
    {/* Third paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Economical & Modish Packaging for your products
    </h3>
    <p className="paragraphText" >
    Our elegant looking custom boxes are inexpensive too. The box packaging for candles is as imperative as the quality of the product for the reason that the Candle Boxes Wholesale will be the first thing to intermingle with the customer.
    Present your Candle Boxes items in a new way that will give your customers an exceptional look with an added touch of stylishness. There are lots of diverse ways to pack your Candles, Custom Box City offers a wide range of Custom Candle Boxes including; Cut-out Window Boxes to display lovely candles and other ornaments, the freshness of items, quality, and style.
    </p>
    </div>
    </div>
    {/* Fourth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Get Personalized Candle Packaging Boxes Now!
    </h3>
    <p className="paragraphText">
    Get Additional Divider Boxes, so products can be positioned separately in a single box to avoid the mess. With our customization choices, any kind of  Candle Boxes Wholesale size and shape can be made that fits your candle product needs. We always manufacturing perfect sizes that avert the bouncing of candles inside the box. Custom Box city offers distinctive shape boxes to provide accommodations with the candles as per their sizes. A distinctively shaped box Candle appeals onlookers and pushes them to buy the candle products for their special occasions. Make a novelty in your usual packaging candle with Custom box City.
    </p>
    </div>
    </div>
    {/* Fifth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Free Doorstep Delivery!
    </h3>
    <p className="paragraphText">
    Now you just need to make a call (510) 500 9533 or email us at Sales@customboxcity.com. Do not forget to follow us on social media as it is always helping to keep in touch with your packaging partner regarding new announcements!  We will deliver the custom cardboard  boxes at doorstep without any extra shipping charges. 
    </p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    )
}

export default CandleBox;