import React from "react";
import image1 from '../Assests/Images/slide1.jpg'
import image2 from '../Assests/Images/abc.jpeg'
import './Slider.css'
// import image2 from '../Assests/Images/tesla.png'
function Slider() {
    return (
        <div className='sliderMainDiv'> 
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
         <ol className="carousel-indicators">
         <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
         <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
         <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
            <div className="carousel-item active">
            <img className="d-block w-100" src={image1} alt="First slide" style={{height:'450px', width:'350px'}}/>
        </div>
        <div className="carousel-item">
            <img className="d-block w-100" src={image2} alt="Second slide" style={{height:'10px'}}/>
        </div>
        <div className="carousel-item">
            <img className="d-block w-100" src={image1} alt="Third slide"/>
        </div>
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
        </a>
        </div>
        </div>
        )
    }
    
    
    export default Slider;
    