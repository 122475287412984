import React from "react";
import "./BakeryBox.css";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import box1 from '../Assests/Images/CustomBakerybox/246.png'
import box2 from '../Assests/Images/CustomBakerybox/bakery-boxes-(1).png'
import box3 from '../Assests/Images/CustomBakerybox/bakery-gift-boxes-oxopackaging.png'
import box4 from '../Assests/Images/CustomBakerybox/Bakery-Kraft-Boxes.png'
import box5 from '../Assests/Images/CustomBakerybox/birthday-gift-boxes-oxopackaging.png'
import box6 from '../Assests/Images/CustomBakerybox/candy2.png'
import box7 from '../Assests/Images/CustomBakerybox/cereal2.png'
import box8 from '../Assests/Images/CustomBakerybox/chocolate2.png'
import box9 from '../Assests/Images/CustomBakerybox/cupcake-boxes.png'
import box10 from '../Assests/Images/CustomBakerybox/custom-brown-bakery-boxes.png'
import box11 from '../Assests/Images/CustomBakerybox/custom-cake-boxes.png'
import box12 from '../Assests/Images/CustomBakerybox/custom-cookie-boxes.png'

import box13 from '../Assests/Images/CustomBakerybox/custom-dessert-kraft-packaging-boxes.png'
import box14 from '../Assests/Images/CustomBakerybox/custom-individual-cupcake-boxes.png'
import box15 from '../Assests/Images/CustomBakerybox/custom-paper-cake-box.png'
import box16 from '../Assests/Images/CustomBakerybox/custom-printed-pie-boxes4.png'
import box17 from '../Assests/Images/CustomBakerybox/custom-single-cupcake-boxes.png'
import box18 from '../Assests/Images/CustomBakerybox/custom-small-cake-boxes.png'
import box19 from '../Assests/Images/CustomBakerybox/custom-window-bakery-box1.png'
import box20 from '../Assests/Images/CustomBakerybox/custom-window-cake-boxes.png'
import box21 from '../Assests/Images/CustomBakerybox/custom-window-dessert-packaging-box.png'

import bannerImg from '../Assests/Images/final-gif.gif';

const BakeryBox=() =>{
    return(
        <div className="mainDiv">
        <div className="jumbotron">
        <h3> Custom Bakery Boxes </h3>
        <img class="card-img-top" src={bannerImg} alt="Card cap"/>
        </div>
    
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box1} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Biscotti Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box2} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Bakery Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box3} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Bakery Gift Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card formCard" style={{width: '18rem'}}>
    <div> <h4 className="customQuoteheading"> Get Custom Quote </h4> </div>
    <div className="card-body formCard">
    <form>
        <div className="form-row">
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="lenght" placeholder="Lenght"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="width" placeholder="width"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="depth" placeholder="depth"/>
            </div>
            <div className="form-group col-md-3">
                <select id="inputInches" class="form-control" placeholder="Inch">
                    <option >cm</option>
                    <option>mm</option>
                    <option>inch</option>
                </select>
            </div>
        </div>
        <div className="form-group">
        <label for="inputAddress2">Product Name</label>
        <select id="inputInches" class="form-control">
                    <option>Auto-lock Boxes</option>
                    <option>Baby product boxes</option>
                    <option>Bakery Boxes</option>
                    <option>Ballot Boxes</option>
                    <option>Bandage Boxes</option>
                    <option>Belt Boxes</option>
                    <option>Black Gable Boxes</option>
                    <option>Book end Boxes</option>
                    <option>Bottle Boxes</option>
                    <option>Bottle Neckers</option>
                    <option>Buisness Card Boxes</option>
                    <option>Cake Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Candy Apple Boxes</option>
                    <option>Candy Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Cardboard Boxes</option>
                    <option>Cardboard Dispenser Boxes</option>
                    <option>Cereal Boxes</option>
                    <option>Choclate Boxes</option>
                    <option>Christmas Boxes</option>
                    <option>Ciggarette Boxes</option>
                    <option>Cookie Boxes</option>
                    <option>Corrugated Boxes</option>
                    <option>Cosmetic Boxes</option>
                    <option>Cosmetic Display Boxes</option>
                    <option>Cream Boxes</option>
                    <option>Cube Boxes</option>
                    <option>Cupcake Boxes</option>
                    <option>Custom Bath Bomb Boxes</option>
                    <option>Custom Electronic Ciggarette Boxes</option>
                    <option>Display Boxes</option>
                    <option>Donut Boxes</option>
                    <option>E-Liquid Boxes</option>
                    <option>Eye Shadow Boxes</option>
                    <option>Eye Liner Boxes</option>
                    <option>Favour Boxes</option>
                    <option>Flap Boxes</option>
                    <option>Foundation Boxes</option>
                    <option>Gable Boxes</option>
                    <option>Game Boxes</option>
                    <option>Gift Boxes</option>
                    <option>Gift Card Boxes</option>
                    <option>Gold Foil Boxes</option>
                    <option>Hair Extension Boxes</option>
                    <option>Hair Spray Boxes</option>
                    <option>Handle Boxes</option>
                    <option>Hanging Tab Boxes</option>
                    <option>Hexagon Boxes</option>
                    <option>Invitation Boxes</option>
                    <option>Kraft Boxes</option>
                    <option>Lip Gloss Boxes</option>
                    <option>lipstick Boxes</option>
                    <option>Lotion Boxes</option>
                    <option>Macaron Boxes</option>
                    <option>Mailer Boxes</option>
                    <option>Makeup Boxes</option>
                    <option>Mascara Boxes</option>
                    <option>Medicine Boxes</option>
                    <option>Muffin Boxes</option>
                    <option>Nail Polish Boxes</option>
                    <option>Ornament Boxes</option>
                    <option>Paper Boxes</option>
                    <option>Paper Cup Boxes</option>
                    <option>Pastry Boxes</option>
                    <option>Perfume Boxes</option>
                    <option>Pie Boxes</option>
                    <option>Pillow Boxes</option>
                    <option>Pizza Boxes</option>
                    <option>Playing Card Boxes</option>
                    <option>Popcorn Boxes</option>
                    <option>Product Boxes</option>
                    <option>Pyramid Boxes</option>
                    <option>Retail Boxes</option>
                    <option>Rigid Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Snack Boxes</option>
                    <option>Soap Boxes</option>
                    <option>Software Boxes</option>
                    <option>Tea Boxes</option>
                    <option>Tie Boxes</option>
                    <option>Toy Boxes</option>
                    <option>USB Boxes</option>
                    <option>Window Boxes</option>
                </select>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
            <label for="inputColor">Color:</label>
            <select id="inputState" class="form-control">
                <option selected>1 Color </option>
                <option>2 Color</option>
                <option>3 Color</option>
                <option>4 Color</option>
                <option>4/1 Color</option>
                <option>4/2 Color</option>
                <option>4/3 Color</option>
                <option>4/4 Color</option>
            </select>
        </div>
    <div class="form-group col-md-6">
        <label for="inputQuantity">Quantity</label>
        <input type="number" class="form-control" id="quantity"/>
    </div>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="Name"/>
    </div>
    <div className="form-row formRow">
    <input type="email" class="form-control" placeholder="Email"/>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="tel"/>
    </div>
        <button type="submit" class="btn btn-primary formBtn">Submit</button>
    </form>
        <hr/>
        </div>
    </div>
    </div>
    </div>
    {/* Second Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box4} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Bakery Kraft Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box5} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Birthday Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box6} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Candy Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Third ROw */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box7} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Cereal Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box8} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Choclate Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box9} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Cupcake Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Fourth Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box10} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Brown Bakery Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box11} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Cakes Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box12} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Cookie Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Fifth Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box13} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Desert Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box14} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5> Individual Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box15} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Paper Cake Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Sixth Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box16} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Printed Pie Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box17} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Single Cupcake Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box18} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Small Cake Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Seventh Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box19} alt="Card cap"/>
    <div class="card-body">
    <h5> Window Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box20} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Window Cake Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box21} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5> Window Desert Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    <div className="row">
    <div className="col-md-12">
    <h3 className="secondHeading"> Bakery Boxes</h3>
    <p className="secondPara"> We facilitate your business by bringing you innovative printed boxes with extraordinary design printing. </p>
    <div className="jumbotron jumboBackground">
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Spectacular Custom Bakery Boxes’ Collection for Your Delectable Baked Treats
    </h3>
    <p className="paragraphText">
    Why are you thinking to waste sum and energy on the publicity of your product and brand? Don’t you think you can have a packing so scrumptious that can reflect the confectionaries you bake? Follow our instincts and get the most catchy and mouthwatering stimulus for your buyers through our Custom Packaging solution to get your Custom Bakery Boxes and foodstuff the most famous one in the town. Our packaging is not only to catch hosts of consumers but the food freshening tactics we trick about will retain them as well hence how we build your brand’s reputation. You can even choose the boxes you find best for your product on your own. Customize them for us to flourish your taste and quality thereby. 
    </p>
    </div>
    </div>
    {/* Second Para */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Seal-In Both the Freshness & Taste with High-Quality Custom Bakery Packaging
    </h3>
    <p className="paragraphText">
    Pastry Chefs and Bakers must use a wide variety of tools, baking products, and packaging to complete and sell their Muffin, Fudge, Popcorn Boxes, Chocolate Boxes, Macaron Boxes, Cereal Boxes, Candy Boxes, Window Cake Boxes, Pie Boxes, Pastry Boxes, Donuts Boxes and Biscotti Gift Boxes. At OXO Packaging we create all these kinds of quality boxes and more. We enable our customers to pitch in their ideas, their personalities, and their inspiration design their own custom bakery packaging. We offer you the finest quality materials and the most creative designers for you to work. If, however, you are still a startup and navigating business ways then we can give the right advice based on our market experience. Our bakery boxes are lined with the special material that seals in food aroma and protects its moisture until the customers are ready to consume your delicious treats 
    </p>
    </div>
    </div>
    {/* Third paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Your Partner for Specialized Wholesale Bakery Boxes
    </h3>
    <p className="paragraphText" >
    After the baking, icing, and decorations are complete, you want to add these your amazing products into a box that does justice to all your hard work. To achieve that perfection, we spend all our efforts and talents into creating the range of custom bakery boxes that will blow your customers' mind. Exaggerated? Maybe so but if your customers love your baking you ought to invest in making the whole experience as best as you possibly can. Making your final packaged product to look eye-catching yet be easy to put together is an art that OXO Packaging understands all too well. With our collection of custom bakery boxes wholesale, colors, and any sizes you have the options to fit all of your various sized Cakes and pies, as well as your chocolate.
    </p>
    </div>
    </div>
    {/* Fourth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Why Custo Box City's Custom Cosmetic Boxes?:
    </h3>
    <p className="paragraphText">
    If you are wondering why should you pick us and not thousands of online vendors worldwide for custom cosmetic boxes? It’s because we have both the style sense and expertise to execute the packaging that will redefine your brand in the market.<br/>
    A vailability of Tailored Design Solution <br/>
    Premium quality stock materials<br/>
    Fast turnaround time<br/>
    No Shipment Charges<br/>
    Affordable Price Plans<br/>
    Error Free, Doorstep Delivery<br/>
    24/7 Customer Support for all Cosmetic Boxes wholesale orders<br/>
    </p>
    </div>
    </div>
    {/* Fifth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Free Doorstep Delivery!
    </h3>
    <p className="paragraphText">
    Now you just need to make a call (510) 500 9533 or email us at Sales@customboxcity.com. Do not forget to follow us on social media as it is always helping to keep in touch with your packaging partner regarding new announcements!  We will deliver the custom cardboard  boxes at doorstep without any extra shipping charges. 
    </p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    )
}

export default BakeryBox;