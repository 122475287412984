import React from "react";
import './LoginForm.css'
function LoginForm() {
    return (
        <div className="login-form-div"> 
        <div class="wrapper fadeInDown">
        <div id="formContent">
        <div class="fadeIn first">
        </div>
        <form>
        <input type="text" id="login" class="fadeIn second" name="name" placeholder="Name"/>
        <input type="text" id="password" class="fadeIn third" name="email" placeholder="email"/>
        <input type="text" id="password" class="fadeIn fourth" name="inquiry" placeholder="inquiry"/>
        <input type="submit" class="fadeIn fifth" value="Submit"/>
        </form>
        <div id="formFooter">
        <a class="underlineHover" href="#">Need Help?</a>
        </div>
        </div>
        </div>
        </div>
        )
    }
    export default LoginForm;