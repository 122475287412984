import React from "react";
import "./Header.css";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import logo from '../Assests/Images/Logo.png'


const header=() =>{
    return(
        <div className="mainDiv">
        <div className="row social-div">
        <div className="col-md-6">
            <br/>
            {/* <br/> */}
        {/* <span> Facebbok </span>
        <span> Twitter </span>
        <span> Instagram </span>
        <span> Linkedin </span> */}
        </div>
        <div className="col-md-6">
        {/* <span> Facebbok </span>
        <span> Twitter </span>
        <span> Instagram </span>
        <span> Linkedin </span> */}
        </div>
        
        </div>
        <nav className="navbar navbar-expand-lg navstyle">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
        <Link className="navbar-brand" to="/home">        
        <img class="card-img-top" src={logo} alt="Card cap" style={{height: '70px'}}/>
        </Link>
        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
        <li className="nav-item active levelHeader">
        <Link className="nav-link" to="/home"> Home <span className="sr-only">(current)</span> </Link>
        </li>
        <li class="dropdown">
        <button class="dropbtn">Boxes By Industry</button>
            <div class="dropdown-content">
               <div className="row">
                   <div className="col-md-3">
                       <h6> Beauty</h6>
                       <hr/>
                        <Link to="/CosmeticBoxes">Cosmetic boxes</Link>
                        <Link to="/HealthBoxes"> Health boxes</Link>
                        <Link to="/PersonalCare">Personal Care boxes</Link>
                        <Link to="/PharmaBox">Pharma boxes</Link>
                        <Link to="/HairExtensions">Hair Extension boxes</Link>
                    </div>
                    <div className="col-md-3">
                       <h6> Consumer Products </h6>
                       <hr/>
                        <Link to="/CandleBox">Candle boxes</Link>
                        <Link to="/SoapBox">Soap boxes</Link>
                        <Link to="/CigaretteBox">Ciggrette boxes</Link>
                        <Link to="/PillowBox">Pillow boxes</Link>
                        <Link to="/ELiquidBox">E-Liquid boxes</Link>
                        <Link to="/RigidBoxes">Rigid boxes</Link>
                        <Link to="/CustomVapeBox">Vape boxes</Link>
                        <Link to="/Hempbox">Hemp boxes</Link>
                    </div>
                    <div className="col-md-3">
                       <h6> Grocery </h6>
                       <hr/>
                        <Link to="/BakeryBox">Custom Bakery Boxes</Link>
                        <Link to="/BeverageBox">Beverage Boxes</Link>
                        <Link to="/FoodBox">Food Boxes</Link>
                        {/* <Link to="/CardboardBoxes">Custom cannabis boxes</Link> */}
                        <Link to="/ChoclateBoxes">Choclate Boxes</Link>
                        <Link to="/CerealBox">Cereal Boxes</Link>
                        <Link to="/PizzaBox">Pizza boxes</Link>
                        {/* <Link to="/CardboardBoxes">CBD display boxes</Link>
                        <Link to="/CardboardBoxes">Custom gummies boxes</Link>
                        <Link to="/CardboardBoxes">Custom isolate boxes</Link>
                        <Link to="/CardboardBoxes">Custom lollipop boxes</Link>
                        <Link to="/CardboardBoxes">CBD lotion boxes</Link>
                        <Link to="/CardboardBoxes">Pain cream boxes</Link>
                        <Link to="/CardboardBoxes">Custom pills boxes</Link>
                        <Link to="/CardboardBoxes">Custom pod boxes</Link>
                        <Link to="/CardboardBoxes">Custom tincture boxes</Link>
                        <Link to="/CardboardBoxes">Custom topical boxes</Link>
                        <Link to="/CardboardBoxes">Hemp flour boxes</Link>
                        <Link to="/CardboardBoxes">Hemp milk boxes</Link>
                        <Link to="/CardboardBoxes">Hemp oil boxes</Link>
                        <Link to="/CardboardBoxes">Hemp teabags boxes</Link>
                        <Link to="/CardboardBoxes">Marijuana Edibles boxes</Link>
                        <Link to="/CardboardBoxes">Organic hemp boxes</Link>
                        <Link to="/CardboardBoxes">Printed CBD oil boxes</Link>
                        <Link to="/CardboardBoxes">Custom thc boxes</Link>
                        <Link to="/CardboardBoxes">Custom E-cigs boxes</Link>
                        <Link to="/CardboardBoxes">Whole-sale marijuana boxes</Link> */}

                    </div>
                    <div className="col-md-3">
                       <h6> Life Style </h6>
                       <hr/>
                        <Link to="/CardboardBoxes">Sports Boxes</Link>
                        <Link to="/CardboardBoxes">Apparel Boxes</Link>
                        <Link to="/CardboardBoxes">Automotive Boxes</Link>
                        <Link to="/CardboardBoxes">100-ml E-liquid boxes</Link>
                        <Link to="/CardboardBoxes">Household Boxes </Link>
                        <Link to="/CardboardBoxes">Bottle Neckers </Link>
                        <Link to="/CardboardBoxes">Custom Hang tags</Link>
                        {/* <Link to="/CardboardBoxes">OG CBD boxes</Link> */}
                    </div>
                </div>
            </div>
        </li>

        
        <li class="dropdown">
        <button class="dropbtn">Custom Printed Boxes</button>
            <div class="dropdown-content">
                <Link to="/CardboardBoxes">Cardboard boxes</Link>
                <Link to='/corrugatedBoxes'>Corrugated Boxes</Link>
                <Link to="/KraftBoxes">Kraft Boxes</Link>
                <Link to='/CustomMailerBoxes'>Custom Mailer Boxes</Link>
            </div>
        </li>
        
            <li className="nav-item levelHeader">
                <Link className="nav-link" to="/platformAndIntegration"> Why Choose Us </Link>
            </li>
            <li className="nav-item levelHeader">
                <Link className="nav-link" to="/platformAndIntegration"> Blog </Link>
            </li>
            <li className="nav-item levelHeader">
                <Link className="nav-link" to="/login"> Request a Quote </Link>
            </li>
        </ul>
        </div>
        </nav>
        </div>
        
        )
        
    }
    
    export default header;