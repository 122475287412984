import React from "react";
import './ProductSpecification.css'


function ProductSpecification() {
    return(
        <div>
       <div className="row leftAligned">
           <h1> Product Specification</h1>
       </div>
       <div className="row leftAligned">
         <div className="col-md-6">
         <h6 className="headings"> Box </h6>
            <h6 className="headings">  Dimension (L + W + H) </h6>
            <h6 className="headings"> Quantities </h6>
            <h6 className="headings"> Paper Stock </h6>
            <h6 className="headings"> Printing </h6>
            <h6 className="headings"> Finishing</h6>
            <h6 className="headings"> Included Options </h6>
            <h6 className="headings"> Additional Options </h6>
            <h6 className="headings"> Proof </h6>
            <h6 className="headings"> Turnaround </h6>
            <h6 className="headings"> Shipping </h6>
         </div>
         <div className="col-md-6 ">
            <h6 className="headings"> Cardboard Boxes </h6>
            <h6 className="headings">  All Custom Sizes & Shapes </h6>
            <h6 className="headings"> No Minimum Order Required </h6>
            <h6 className="headings"> 10pt to 28pt (60lb to 400lb) Eco-Friendly Kraft, E-flute Corrugated, Bux Board, Cardstock </h6>
            <h6 className="headings"> Printing </h6>
            <h6 className="headings"> No Printing, CMYK, CMYK + 1 PMS color, CMYK + 2 PMS colors</h6>
            <h6 className="headings"> Gloss Lamination, Matte Lamination, Gloss AQ, Gloss UV, Matte UV, Spot UV, Embossing, Foiling </h6>
            <h6 className="headings"> Die Cutting, Gluing, Scored,Perforation </h6>
            <h6 className="headings"> Eco-Friendly, Recycled Boxes, Biodegradable </h6>
            <h6 className="headings"> Flat View, 3D Mock-up, Physical Sampling (On request) </h6>
            <h6 className="headings"> FLAT </h6>
        </div>
       </div>
       </div>

    )
}
export default ProductSpecification;