import React from "react";
import './MainBanner.css'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import cardImg1 from '../Assests/Images/custom-cannabis-tinctures-packaging-boxes.png'
import cardImg2 from '../Assests/Images/vape-cartidge-box-packaging.png'
import cardImg3 from '../Assests/Images/custom-lipbalm-boxes1.png'
import cardImg4 from '../Assests/Images/csutom-pillow-printed-boxes.png'
import cardImg5 from '../Assests/Images/custom-gable-boxes1.png'
import cardImg6 from '../Assests/Images/hair-extension-boxes.png'


function MainBanner() {
    return(
        <div className="banner"> 
          <div class="jumbotron">
          <h1 class="display-4">CBD-Boxes Supplier in the USA Custom Printed CBD Packaging</h1>
          <p class="lead">We are accustomed to help extensive range of businesses through our well-designed and durable CBD packaging that caters to reliability.</p>
        <hr class="my-4"/>
        <div class="row">

          <div className="col-md-4">
            <div class="card" style={{width: '18rem'}}>
              <img class="card-img-top" src={cardImg1} alt="Card cap"/>
                <div class="card-body">
                  <hr/>
                  <h5>   Tincture Boxes </h5>
                  <Link to="/TinctureBox">
                  <button className="btnStarted"> Customize now </button>
                  </Link>
                </div>
            </div>
          </div>

          <div className="col-md-4">
            <div class="card" style={{width: '18rem'}}>
              <img class="card-img-top" src={cardImg2} alt="Card cap"/>
                <div class="card-body">
                  <hr/>
                  <h5>   Vape boxes </h5>
                  <Link to="/VapeBox">
                    <button className="btnStarted"> Customize now </button>
                    </Link>
                </div>
            </div>
          </div>
          <div className="col-md-4">
            <div class="card" style={{width: '18rem'}}>
              <img class="card-img-top" src={cardImg3} alt="Card cap"/>
                <div class="card-body">
                  <hr/>
                  <h5>   Lip-Balm Boxes </h5>
                  <Link to="/LipBalm">
                  <button className="btnStarted"> Customize now </button>
                  </Link>
                </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="col-md-4">
            <div class="card" style={{width: '18rem'}}>
              <img class="card-img-top" src={cardImg4} alt="Card cap"/>
                <div class="card-body">
                  <hr/>
                  <h5>   Pillow Boxes </h5>
                  <Link to="/PillowBoxes">
                  <button className="btnStarted"> Customize now </button>
                  </Link>
                </div>
            </div>
          </div>

          <div className="col-md-4">
            <div class="card" style={{width: '18rem'}}>
              <img class="card-img-top" src={cardImg5} alt="Card cap"/>
                <div class="card-body">
                  <hr/>
                  <h5>   Gabble boxes </h5>
                  <Link to="/GableBoxes">
                  <button className="btnStarted"> Customize now </button>
                  </Link>
                </div>
            </div>
          </div>
          <div className="col-md-4">
            <div class="card" style={{width: '18rem'}}>
              <img class="card-img-top" src={cardImg6} alt="Card cap"/>
                <div class="card-body">
                  <hr/>
                  <h5>   Hair Extension Boxes </h5>
                  <Link to="/HairExtension">
                  <button className="btnStarted"> Customize now </button>
                  </Link>
                </div>
            </div>
          </div> 
        </div>      
      </div>
      </div>
    )
}
export default MainBanner;