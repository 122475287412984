import React from "react";
import "./CosmeticBoxes.css";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import box1 from '../Assests/Images/BeverageBox/coffee22.png'
import box2 from '../Assests/Images/BeverageBox/tea21.png'
import box3 from '../Assests/Images/BeverageBox/coffee22.png'

// import box4 from '../Assests/Images/CosmeticBoxes/custom-eyeshadow-boxes.png'
// import box5 from '../Assests/Images/CosmeticBoxes/custom-founation-boxes.png'
// import box6 from '../Assests/Images/CosmeticBoxes/custom-lipbalm-boxes1.png'
// import box7 from '../Assests/Images/CosmeticBoxes/custom-lip-balm-display-boxes.png'
// import box8 from '../Assests/Images/CosmeticBoxes/custom-lipstick-box-packaging.png'
// import box9 from '../Assests/Images/CosmeticBoxes/custom-makeup-boxes-wholesale1.png'
// import box10 from '../Assests/Images/CosmeticBoxes/custom-nailpoilsh-boxes.png'
// import box11 from '../Assests/Images/CosmeticBoxes/custom-sanitizer-boxes.png'
// import box12 from '../Assests/Images/CosmeticBoxes/custom-sun-protection-cream-box.png'

// import box13 from '../Assests/Images/CosmeticBoxes/general-copsmetic-box.png'
// import box14 from '../Assests/Images/CosmeticBoxes/glossylotion2.png'
// import box15 from '../Assests/Images/CosmeticBoxes/hair-extension-boxes.png'
// import box16 from '../Assests/Images/CosmeticBoxes/hair-spray-box-2nd.png'
// import box17 from '../Assests/Images/CosmeticBoxes/kraft-lipstick-box.png'
// import box18 from '../Assests/Images/CosmeticBoxes/mascara-box-main-2.png'
// import box19 from '../Assests/Images/CosmeticBoxes/perfume2.png'
// import box20 from '../Assests/Images/CosmeticBoxes/unique-cosmetoic-box.png'
// import box21 from '../Assests/Images/CosmeticBoxes/Wax_Stripes-Boxes.png'

import bannerImg from '../Assests/Images/final-gif.gif';

const BeverageBox=() =>{
    return(
        <div className="mainDiv">
        <div className="jumbotron">
        <h3> Custom Beverage Boxes </h3>
        <img class="card-img-top" src={bannerImg} alt="Card cap"/>
        </div>
    
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box1} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Coffee Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box2} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Tea Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box3} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Juice Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card formCard" style={{width: '18rem'}}>
    <div> <h4 className="customQuoteheading"> Get Custom Quote </h4> </div>
    <div className="card-body formCard">
    <form>
        <div className="form-row">
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="lenght" placeholder="Lenght"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="width" placeholder="width"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="depth" placeholder="depth"/>
            </div>
            <div className="form-group col-md-3">
                <select id="inputInches" class="form-control" placeholder="Inch">
                    <option >cm</option>
                    <option>mm</option>
                    <option>inch</option>
                </select>
            </div>
        </div>
        <div className="form-group">
        <label for="inputAddress2">Product Name</label>
        <select id="inputInches" class="form-control">
                    <option>Auto-lock Boxes</option>
                    <option>Baby product boxes</option>
                    <option>Bakery Boxes</option>
                    <option>Ballot Boxes</option>
                    <option>Bandage Boxes</option>
                    <option>Belt Boxes</option>
                    <option>Black Gable Boxes</option>
                    <option>Book end Boxes</option>
                    <option>Bottle Boxes</option>
                    <option>Bottle Neckers</option>
                    <option>Buisness Card Boxes</option>
                    <option>Cake Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Candy Apple Boxes</option>
                    <option>Candy Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Cardboard Boxes</option>
                    <option>Cardboard Dispenser Boxes</option>
                    <option>Cereal Boxes</option>
                    <option>Choclate Boxes</option>
                    <option>Christmas Boxes</option>
                    <option>Ciggarette Boxes</option>
                    <option>Cookie Boxes</option>
                    <option>Corrugated Boxes</option>
                    <option>Cosmetic Boxes</option>
                    <option>Cosmetic Display Boxes</option>
                    <option>Cream Boxes</option>
                    <option>Cube Boxes</option>
                    <option>Cupcake Boxes</option>
                    <option>Custom Bath Bomb Boxes</option>
                    <option>Custom Electronic Ciggarette Boxes</option>
                    <option>Display Boxes</option>
                    <option>Donut Boxes</option>
                    <option>E-Liquid Boxes</option>
                    <option>Eye Shadow Boxes</option>
                    <option>Eye Liner Boxes</option>
                    <option>Favour Boxes</option>
                    <option>Flap Boxes</option>
                    <option>Foundation Boxes</option>
                    <option>Gable Boxes</option>
                    <option>Game Boxes</option>
                    <option>Gift Boxes</option>
                    <option>Gift Card Boxes</option>
                    <option>Gold Foil Boxes</option>
                    <option>Hair Extension Boxes</option>
                    <option>Hair Spray Boxes</option>
                    <option>Handle Boxes</option>
                    <option>Hanging Tab Boxes</option>
                    <option>Hexagon Boxes</option>
                    <option>Invitation Boxes</option>
                    <option>Kraft Boxes</option>
                    <option>Lip Gloss Boxes</option>
                    <option>lipstick Boxes</option>
                    <option>Lotion Boxes</option>
                    <option>Macaron Boxes</option>
                    <option>Mailer Boxes</option>
                    <option>Makeup Boxes</option>
                    <option>Mascara Boxes</option>
                    <option>Medicine Boxes</option>
                    <option>Muffin Boxes</option>
                    <option>Nail Polish Boxes</option>
                    <option>Ornament Boxes</option>
                    <option>Paper Boxes</option>
                    <option>Paper Cup Boxes</option>
                    <option>Pastry Boxes</option>
                    <option>Perfume Boxes</option>
                    <option>Pie Boxes</option>
                    <option>Pillow Boxes</option>
                    <option>Pizza Boxes</option>
                    <option>Playing Card Boxes</option>
                    <option>Popcorn Boxes</option>
                    <option>Product Boxes</option>
                    <option>Pyramid Boxes</option>
                    <option>Retail Boxes</option>
                    <option>Rigid Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Snack Boxes</option>
                    <option>Soap Boxes</option>
                    <option>Software Boxes</option>
                    <option>Tea Boxes</option>
                    <option>Tie Boxes</option>
                    <option>Toy Boxes</option>
                    <option>USB Boxes</option>
                    <option>Window Boxes</option>
                </select>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
            <label for="inputColor">Color:</label>
            <select id="inputState" class="form-control">
                <option selected>1 Color </option>
                <option>2 Color</option>
                <option>3 Color</option>
                <option>4 Color</option>
                <option>4/1 Color</option>
                <option>4/2 Color</option>
                <option>4/3 Color</option>
                <option>4/4 Color</option>
            </select>
        </div>
    <div class="form-group col-md-6">
        <label for="inputQuantity">Quantity</label>
        <input type="number" class="form-control" id="quantity"/>
    </div>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="Name"/>
    </div>
    <div className="form-row formRow">
    <input type="email" class="form-control" placeholder="Email"/>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="tel"/>
    </div>
        <button type="submit" class="btn btn-primary formBtn">Submit</button>
    </form>
        <hr/>
        </div>
    </div>
    </div>
    </div>
    {/* Second Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box4} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Eye shadow Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box5} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Foundation Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box6} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Lipbalm Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Third ROw */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box7} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Lipbalm Display Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box8} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Lipstick Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box9} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Makeup Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Fourth Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box10} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Makeup Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box11} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  New NailPolish Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box12} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Sanitizer Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Fifth Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box13} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Sunspot Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box14} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  General Cosmetic Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box15} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Glossy lotion Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Sixth Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box16} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Hair Extension Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box17} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Hair Spray Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box18} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Lipstick Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    {/* Seventh Row */}
    {/* <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box19} alt="Card cap"/>
    <div class="card-body">
    <h5> Mascara Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box20} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Perfume Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box21} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Unique Cosmetic Boxes </h5>
    </div>
    </div>
    </div>
    </div> */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="secondHeading"> Beverage Boxes</h3>
    <p className="secondPara"> We facilitate your business by bringing you innovative printed boxes with extraordinary design printing. </p>
    <div className="jumbotron jumboBackground">
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Custom Cosmetic Packaging Boxes to Encase Your Makeup Collection
    </h3>
    <p className="paragraphText">
    The major part of uplifting the brand’s reputation is marked by how it puts its products across. It reflects not only your thoughts about your own standards but how much you value your consumers’ attention too. Custom Cosmetic Packaging Boxes give your product a dashing first look that leads him/her to purchase or even be tempted to purchase. Choose the best appearance to make your customers believe in your elegance and finesse. From cosmetics products to makeover accessories, we make a stunning difference in your services and retail business.
    </p>
    </div>
    </div>
    {/* Second Para */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Expert designers at Custom Box City know how to impress with “looks”
    </h3>
    <p className="paragraphText">
    If there is one thing a cosmetic brand owner knows is that looks mean everything when it comes to cosmetic packaging boxes. What we mean to say is that how can you expect your customers to trust your products to make them look beautiful if they come in low-standard boxes. Regular buyers of cosmetics are conscious of their brands as their skincare is in question. We at Custom Box City, know how to convince customers that your products are up to the mark, with our high-class box designs. We create super-efficient and super-classy Custom Printed Cosmetic Boxes wholesale for all product range. You can get uni-themed customized cases for all items in your cosmtic collection like lipstick tubes, eyeshadows, compacts, hair extensions, foundations, perfumes, scents, eyeliner, mascaras, lip gloss, day/night creams. Custom box City experts can help you design all these cases in a way that has a similar brand theme but stylish in its own right. You can benefit from the expertise of veterans who can design the cosmetic wholesale boxes your customers will recognize in a single look.

You can give us the product details you want on your cosmetic containers and we will print them in a way that will give the product a flattering impact. 
    </p>
    </div>
    </div>
    {/* Third paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Die-Cut Cosmetic Packaging; Let Your Creativity Reign Free:
    </h3>
    <p className="paragraphText" >
    The very term ‘custom cosmetic boxes’ means that you are free to make your unique product boxes in any shape you like. Your brand doesn’t need to conform to the norms and use the same layout as dozens of other websites. You can bring your own personality into the cosmetic products you create because if you let your passion speak your clients will be impressed and inspired. Nobody cares for grey, strictly corporate designs when it comes to make-up brands. You have to be simple yet bold and colorful.
    </p>
    </div>
    </div>
    {/* Fourth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Why Custo Box City's Custom Cosmetic Boxes?:
    </h3>
    <p className="paragraphText">
    If you are wondering why should you pick us and not thousands of online vendors worldwide for custom cosmetic boxes? It’s because we have both the style sense and expertise to execute the packaging that will redefine your brand in the market.<br/>
    A vailability of Tailored Design Solution <br/>
    Premium quality stock materials<br/>
    Fast turnaround time<br/>
    No Shipment Charges<br/>
    Affordable Price Plans<br/>
    Error Free, Doorstep Delivery<br/>
    24/7 Customer Support for all Cosmetic Boxes wholesale orders<br/>
    </p>
    </div>
    </div>
    {/* Fifth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Free Doorstep Delivery!
    </h3>
    <p className="paragraphText">
    Now you just need to make a call (510) 500 9533 or email us at Sales@customboxcity.com. Do not forget to follow us on social media as it is always helping to keep in touch with your packaging partner regarding new announcements!  We will deliver the custom cardboard  boxes at doorstep without any extra shipping charges. 
    </p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    )
}

export default BeverageBox;