import React from "react";
import "./KraftBoxes.css";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import box1 from '../Assests/Images/KraftBoxes/cake-kraft-boxes.png'
import box2 from '../Assests/Images/KraftBoxes/cookie-kraft-boxes.png'
import box3 from '../Assests/Images/KraftBoxes/custom-kraft-paper-pillow-boxes.png'
import box4 from '../Assests/Images/KraftBoxes/foldable-kraft-gift-box.png'
import box5 from '../Assests/Images/KraftBoxes/handle-kraft-boxes.png'
import box6 from '../Assests/Images/KraftBoxes/Kraft_Retail_Packaging.png'
import box7 from '../Assests/Images/KraftBoxes/kraftapparel2.png'
import box8 from '../Assests/Images/KraftBoxes/kraft-diecut-box.png'
import box9 from '../Assests/Images/KraftBoxes/kraftdisplay2.png'
import box10 from '../Assests/Images/KraftBoxes/kraft-gift-packaging-boxes.png'
import box11 from '../Assests/Images/KraftBoxes/kraft-lipstick-box.png'
import box12 from '../Assests/Images/KraftBoxes/kraft-mailing-box.png'
import box13 from '../Assests/Images/KraftBoxes/kraft-paper-pillow-gift-boxes-oxopackaging.png'
import box14 from '../Assests/Images/KraftBoxes/kraft-sandwich-box.png'
import box15 from '../Assests/Images/KraftBoxes/kraftsoap2.png'
import box16 from '../Assests/Images/KraftBoxes/kraft-tea-box.png'
import box17 from '../Assests/Images/KraftBoxes/kraft-tuck-top-box.png'
import box18 from '../Assests/Images/KraftBoxes/Kraftwhite2.png'
import box19 from '../Assests/Images/KraftBoxes/kraftwindowbags2.png'
import box20 from '../Assests/Images/KraftBoxes/kraft-window-box1.png'
import box21 from '../Assests/Images/KraftBoxes/window-kraft-boxes.png'
import bannerImg from '../Assests/Images/final-gif.gif';

const KraftBoxes=() =>{
    return(
        <div className="mainDiv">
        <div className="jumbotron">
        <h3> Kraft Boxes </h3>
        <img class="card-img-top" src={bannerImg} alt="Card cap"/>
        </div>
    
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box1} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Cake Kraft Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box2} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Cookie Kraft Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box3} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Custom Pillow Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card formCard" style={{width: '18rem'}}>
    <div> <h4 className="customQuoteheading"> Get Custom Quote </h4> </div>
    <div className="card-body formCard">
    <form>
        <div className="form-row">
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="lenght" placeholder="Lenght"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="width" placeholder="width"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="depth" placeholder="depth"/>
            </div>
            <div className="form-group col-md-3">
                <select id="inputInches" class="form-control" placeholder="Inch">
                    <option >cm</option>
                    <option>mm</option>
                    <option>inch</option>
                </select>
            </div>
        </div>
        <div className="form-group">
        <label for="inputAddress2">Product Name</label>
        <select id="inputInches" class="form-control">
                    <option>Auto-lock Boxes</option>
                    <option>Baby product boxes</option>
                    <option>Bakery Boxes</option>
                    <option>Ballot Boxes</option>
                    <option>Bandage Boxes</option>
                    <option>Belt Boxes</option>
                    <option>Black Gable Boxes</option>
                    <option>Book end Boxes</option>
                    <option>Bottle Boxes</option>
                    <option>Bottle Neckers</option>
                    <option>Buisness Card Boxes</option>
                    <option>Cake Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Candy Apple Boxes</option>
                    <option>Candy Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Cardboard Boxes</option>
                    <option>Cardboard Dispenser Boxes</option>
                    <option>Cereal Boxes</option>
                    <option>Choclate Boxes</option>
                    <option>Christmas Boxes</option>
                    <option>Ciggarette Boxes</option>
                    <option>Cookie Boxes</option>
                    <option>Corrugated Boxes</option>
                    <option>Cosmetic Boxes</option>
                    <option>Cosmetic Display Boxes</option>
                    <option>Cream Boxes</option>
                    <option>Cube Boxes</option>
                    <option>Cupcake Boxes</option>
                    <option>Custom Bath Bomb Boxes</option>
                    <option>Custom Electronic Ciggarette Boxes</option>
                    <option>Display Boxes</option>
                    <option>Donut Boxes</option>
                    <option>E-Liquid Boxes</option>
                    <option>Eye Shadow Boxes</option>
                    <option>Eye Liner Boxes</option>
                    <option>Favour Boxes</option>
                    <option>Flap Boxes</option>
                    <option>Foundation Boxes</option>
                    <option>Gable Boxes</option>
                    <option>Game Boxes</option>
                    <option>Gift Boxes</option>
                    <option>Gift Card Boxes</option>
                    <option>Gold Foil Boxes</option>
                    <option>Hair Extension Boxes</option>
                    <option>Hair Spray Boxes</option>
                    <option>Handle Boxes</option>
                    <option>Hanging Tab Boxes</option>
                    <option>Hexagon Boxes</option>
                    <option>Invitation Boxes</option>
                    <option>Kraft Boxes</option>
                    <option>Lip Gloss Boxes</option>
                    <option>lipstick Boxes</option>
                    <option>Lotion Boxes</option>
                    <option>Macaron Boxes</option>
                    <option>Mailer Boxes</option>
                    <option>Makeup Boxes</option>
                    <option>Mascara Boxes</option>
                    <option>Medicine Boxes</option>
                    <option>Muffin Boxes</option>
                    <option>Nail Polish Boxes</option>
                    <option>Ornament Boxes</option>
                    <option>Paper Boxes</option>
                    <option>Paper Cup Boxes</option>
                    <option>Pastry Boxes</option>
                    <option>Perfume Boxes</option>
                    <option>Pie Boxes</option>
                    <option>Pillow Boxes</option>
                    <option>Pizza Boxes</option>
                    <option>Playing Card Boxes</option>
                    <option>Popcorn Boxes</option>
                    <option>Product Boxes</option>
                    <option>Pyramid Boxes</option>
                    <option>Retail Boxes</option>
                    <option>Rigid Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Snack Boxes</option>
                    <option>Soap Boxes</option>
                    <option>Software Boxes</option>
                    <option>Tea Boxes</option>
                    <option>Tie Boxes</option>
                    <option>Toy Boxes</option>
                    <option>USB Boxes</option>
                    <option>Window Boxes</option>
                </select>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
            <label for="inputColor">Color:</label>
            <select id="inputState" class="form-control">
                <option selected>1 Color </option>
                <option>2 Color</option>
                <option>3 Color</option>
                <option>4 Color</option>
                <option>4/1 Color</option>
                <option>4/2 Color</option>
                <option>4/3 Color</option>
                <option>4/4 Color</option>
            </select>
        </div>
    <div class="form-group col-md-6">
        <label for="inputQuantity">Quantity</label>
        <input type="number" class="form-control" id="quantity"/>
    </div>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="Name"/>
    </div>
    <div className="form-row formRow">
    <input type="email" class="form-control" placeholder="Email"/>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="tel"/>
    </div>
        <button type="submit" class="btn btn-primary formBtn">Submit</button>
    </form>
        <hr/>
        </div>
    </div>
    </div>
    </div>
    {/* Second Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box4} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Foldable Kraft Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box5} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Handle Kraft Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box6} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Retail Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Third ROw */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box7} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Apparel Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box8} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Diecut Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box9} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Display Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Fourth Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box10} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Gable Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box11} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Gift Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box12} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Liptick Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Fifth Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box13} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Mailing Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box14} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Paper Pillow Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box15} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Sandwich Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Sixth Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box16} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Soap Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box17} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Tea Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box18} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft tuck top Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Seventh Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box19} alt="Card cap"/>
    <div class="card-body">
        <hr/>
    <h5> Kraft White Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box20} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  kraft Window Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box21} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Window Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    <div className="row">
    <div className="col-md-12">
    <h3 className="secondHeading"> Kraft Boxes</h3>
    <p className="secondPara"> We facilitate your business by bringing you innovative printed boxes with extraordinary design printing. </p>
    <div className="jumbotron jumboBackground">
    <div className="row">
    {/* <div className="col-md-12">
    <h3 className="textHeading"> 
    Strengthen your Packaging Standards Custom Cardboard Boxes 
    </h3>
    <p className="paragraphText">
    The Custom Cardboard Boxes are the most useful container or every industry! They give extra strength to your product packaging and that’s why manufacturers and retailers are always looking to hunt stylish cardboard boxes. The OXO PACKAGING one of the kingpins of the market of the United States of America and we are proud to be a leading packaging supplier.
    </p>
    </div> */}
    </div>
    {/* Second Para */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Appealing yet Exceptional quality Custom Kraft Boxes
    </h3>
    <p className="paragraphText">
    Kraft Box Packaging is a vital part of any business and its products. The packaging of the product should be amazing and appealing so that customer may attract towards your product and pick among thousands of other products.
    OXO Packaging is having an extensive variety of Custom Kraft Box Packaging and can manufacture appealing yet exceptional quality Custom Kraft Boxes packaging for your business. We offer options for designing in multiple sizes, styles, and dimensions. With the perfect Die Cut, you can customize your Kraft paper box packaging to best fit your product’s needs.
    </p>
    </div>
    </div>
    {/* Third paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Kraft is the most favored Biodegradable Stock
    </h3>
    <p className="paragraphText" >
    You can have highest quality printing and design outcomes for your Kraft Paper Boxes, using digital and offset technology, equipment and methods with specialty ink to print & design on your Customized Kraft Soap Boxes. Sophisticated touches and designs on these Custom Kraft Soap Boxes will get you a new amazing image that’ll help raise your sales and profit margins, without spending unnecessary amounts of money.
    Kraft is the most favored biodegradable stock, these paper wholesale boxes are certainly brown in color but you can have any shades that you want, reproduced boxes with our state-of-the-art equipment. Imaginative boxes are also very prevalent because of their regular grace which also benefits to keep your printing costs per box as low as conceivable. We can deliver you these boxes and you can proudly sell your products packed with Kraft Paper Boxes to your valued customers.
    </p>
    </div>
    </div>
    {/* Fourth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Distinguish Custom Kraft Packaigng can be used for Promotional Purposes
    </h3>
    <p className="paragraphText">
    Many businesspersons across the world are in hunt of distinguishing Custom Kraft Boxes that they can use for promotion and promotional purposes. Instead of going for flamboyant boxes, some prefer the exceptional simplicity of paper Custom Kraft Boxes and get their logo and slogan exhibited on paper boxes. Are you one of those artistic minds? We will deliver you the faultless printing on boxes according to the items you want to pack inside. We have a group of experienced designers for box packaging who will guide you in this assembly of packaging. You can also get a holder on top of your Custom Printed Boxes to afford convenient carrying facility and stand out in the market with a distinctive style. We also provide Kraft gable boxes to make your boxes a spring of product distinction for your business among many others.
    Call OXO Packaging at (510) 500 9533 or Email Us at sales@oxopackaging.com for customer services to get a custom quote now! We make sure our prices will be minimal. You can even enjoy free shipping on minimum order. 
    </p>
    </div>
    </div>
    {/* Fifth paragraph */}
    {/* <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Deliver the eventual Custom Corrugated Boxes
    </h3>
    <p className="paragraphText">
    Show inventiveness on wholesale printed custom size boxes to make their effort and delivery impactful. We help you goal customers with your logo by familiarizing your top-secret products in custom corrugates boxes to capture the household market. As for shapes, use gable, window, pillow, and cube-shaped for products, retail items or food items in your custom corrugated boxes with logo. We deliver the eventual Custom Mailer Boxes with logo and extravagant design selections. It is an inventive approach to transform the industry by transporting incredible quality of wholesale custom packaging boxes. 
    </p>
    </div>
    </div> */}
    </div>
    </div>
    </div>
    </div>
    )
}

export default KraftBoxes;