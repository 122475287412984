import React from "react";
import "./Documentation.css";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

const documentation=() =>{
    return(
        <div className="main-div">
        <div className="nav-side-menu">
        <div className="brand">Emulate Energy</div>
        <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>
        
        <div className="menu-list">
        
        <ul id="menu-content" className="menu-content collapse out">
        <li>
        <a href="#">
        <i className="fa fa-dashboard fa-lg"></i> Registration for Api access
        </a>
        </li>
        
        <li  data-toggle="collapse" data-target="#products" className="collapsed active">
        <a href="#"><i className="fa fa-gift fa-lg"></i> Authorization <span className="arrow"></span></a>
        </li>
        <ul className="sub-menu collapse" id="products">
        <li className="active"><a href="#">RFT-H1</a></li>
        <li><a href="#">RFT-H2</a></li>
        <li><a href="#">BTB-H1</a></li>
        <li><a href="#">BTB-H2</a></li>
        </ul>
        
        
        <li data-toggle="collapse" data-target="#service" className="collapsed">
        <a href="#"><i className="fa fa-globe fa-lg"></i> Errors and Problems <span className="arrow"></span></a>
        </li>  
        <ul className="sub-menu collapse" id="service">
        <li>Trendmonitoring</li>
        <li>Alarmmonitoring</li>
        <li>Audit-Trail</li>
        </ul>
        
        
        <li data-toggle="collapse" data-target="#new" className="collapsed">
        <a href="#"><i className="fa fa-car fa-lg"></i> Charging Location <span className="arrow"></span></a>
        </li>
        <ul className="sub-menu collapse" id="new">
        <li>Alarmstatistik</li>
        <li>Prozessfähigkeit</li>
        </ul>
        
        
        <li>
        <a href="#">
        <i className="fa fa-user fa-lg"></i> Webhooks
        </a>
        </li>
        
        <li data-toggle="collapse" data-target="#new" className="collapsed">
        <a href="#"><i className="fa fa-car fa-lg"></i> Service <span className="arrow"> </span></a>
        </li>
        <ul className="sub-menu collapse" id="new">
        <li>Sensorkonfiguration</li>
        <li>Betriebsarten</li>
        </ul>
        
        </ul>
        </div>
        </div>
        <h1 style={{marginLeft: '20rem'}}> Energy  </h1>
        <p style={{marginLeft: '20rem'}}>  
What We Do
Traditional batteries store electric energy and release it on command. Virtual batteries use flexibility in electricity usage to mimic this exact function. For example, by aggregating flexibility in indoor temperature settings or charging patterns of electric vehicles, Emulate’s algorithms control the loads to charge and discharge energy – exactly like physical batteries.
Virtual Batteries cost a fraction of physical batteries and have, in contrast, no environmental footprint. IOT-connected electric devices, such as smart thermostats and electric vehicle chargers, are growing exponentially. This mega-trend implies that, in the near future many of us will be able to participate in the creation of virtual batteries. 
Virtual batteries can be leased to a range of stakeholders within the community for a variety of renewable energy applications.
Traditional batteries store electric energy and release it on command. Virtual batteries use flexibility in electricity usage to mimic this exact function. For example, by aggregating flexibility in indoor temperature settings or charging patterns of electric vehicles, Emulate’s algorithms control the loads to charge and discharge energy – exactly like physical batteries.
Virtual Batteries cost a fraction of physical batteries and have, in contrast, no environmental footprint. IOT-connected electric devices, such as smart thermostats and electric vehicle chargers, are growing exponentially. This mega-trend implies that, in the near future many of us will be able to participate in the creation of virtual batteries. 
Virtual batteries can be leased to a range of stakeholders within the community for a variety of renewable energy applications.
raditional batteries store electric energy and release it on command. Virtual batteries use flexibility in electricity usage to mimic this exact function. For example, by aggregating flexibility in indoor temperature settings or charging patterns of electric vehicles, Emulate’s algorithms control the loads to charge and discharge energy – exactly like physical batteries.
Virtual Batteries cost a fraction of physical batteries and have, in contrast, no environmental footprint. IOT-connected electric devices, such as smart thermostats and electric vehicle chargers, are growing exponentially. This mega-trend implies that, in the near future many of us will be able to participate in the creation of virtual batteries. 
Virtual batteries can be leased to a range of stakeholders within the community for a variety of renewable energy applications.
Traditional batteries store electric energy and release it on command. Virtual batteries use flexibility in electricity usage to mimic this exact function. For example, by aggregating flexibility in indoor temperature settings or charging patterns of electric vehicles, Emulate’s algorithms control the loads to charge and discharge energy – exactly like physical batteries.
Virtual Batteries cost a fraction of physical batteries and have, in contrast, no environmental footprint.no environmental footpri</p>
     </div>
        
        )
    }
    
    export default documentation;