import React from "react";
import icon1 from '../Assests/Images/Icons-min.png'
import icon2 from '../Assests/Images/Icons-5-min.png'
import icon3 from '../Assests/Images/Icons-4-min.png'
import icon4 from '../Assests/Images/Icons-3-min.png'
import icon5 from '../Assests/Images/Icons-2-min.png'
import icon6 from '../Assests/Images/Icons-1-min.png'



const iconSection =() =>{
    return(
        <div className="mainDiv">
            <h1 className="heading"> CBD Boxes Provides Wide Range of Custom Printed Boxes For Your CBD Products </h1>
            <p className="heading"> Curating a combination of quick turnaround time and effective design, we have created the top-notch CBD boxes for your business needs.</p>
            <div className='row iconsbar'>
                <div className="col-md-2 ">
                    <img class="card-img-top" src={icon1} alt="quality printing" />
                </div>
                <div className="col-md-2">
                    <img class="card-img-top" src={icon2} alt="Free Shipping"/>
                </div>
                <div className="col-md-2">
                    <img class="card-img-top" src={icon3} alt="Free Design Support"/>
                </div>
                <div className="col-md-2">
                    <img class="card-img-top" src={icon4} alt="Fast Turnaround"/>
                </div>
                <div className="col-md-2">
                    <img class="card-img-top" src={icon5} alt="Custom Size and Shape"/>
                </div>  
                <div className="col-md-2">
                    <img class="card-img-top" src={icon6} alt="Custom Size and Shape"/>
                </div>    
            </div> 

        </div>
    )

}

export default iconSection