import React from "react";
import "./Home.css";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Slider from "../components/Slider";
import MainBanner from "../components/MainBanner";
import IconSec from "../components/IconSec"
import HomeText from "../components/HomeText";

const home=() =>{
    return(
        <div> 
            <Slider/>
            <IconSec/>
            <MainBanner/>
            <HomeText/>
            
        </div>
    )
}
export default home;