import React from "react";
import cardImg1 from '../Assests/Images/CBD-Tinccture-Boxes.jpg'
import './Card.css'


function Card() {
    return(
        <div className="main-div-card"> 
            <div class="card" style={{width: '18rem'}}>
                <img class="card-img-top" src={cardImg1} alt="Card cap"/>
            <div class="card-body">
                <h4>  CBD Tincture Boxes  </h4>
                 {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
            </div>
            </div>
        </div>
        )
    }
    export default Card;