import React from "react";
import "./Footer.css";
import logo from '../Assests/Images/Logo.png'
import footerImg from '../Assests/Images/footerImg.png'



function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          <div className="col">
            <h4>About Us</h4>
              <img class="card-img-top" src={logo} alt="Card cap" style={{height: '150px'}}/>
            <p className="list-unstyled1">
            With our wide variety of quality custom CBD packaging at low prices, you can create the perfect packaging for your CBD products. 
            We offer free shipping of custom printed CBD boxes.
            </p>
          </div>
          {/* Column2 */}
          <div className="col">
            <h4>Information</h4>
            <ui className="list-unstyled">
              <li>Privacy Policy</li>
              <li>Terms and Services</li>
              <li>Contact</li>
              <li>Paper Weight Chart</li>
              <li>Sitemap</li>

            </ui>
          </div>
          <div className="col">
            <h4>Packaging Products</h4>
            <ui className="list-unstyled">
              <li>CBD Boxes</li>
              <li>Vape Cartridge Box</li>
              <li>Vape Packaging</li>
              <li>Vape Pen Packaging</li>
              <li>E-Cigarette Liquid Boxes</li>
              <li>Cigarette Boxes</li>
              <li>Cigar Packaging</li>
              <li>Cannabis Boxes</li>

            </ui>
          </div>
          {/* Column3 */}
          <div className="col">
            <h4>Contact Us</h4>
            <hr className="horizontal-line"/>
            <ui className="list-unstyled">
              {/* <input placeholder="Plese enter your email" style={{borderRadius:"5px"}}></input> */}
              <div className="input-group mb-3">
              <input type="text" className="form-control" placeholder="Enter Email" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                  <div className="input-group-append">
                    {/* <span className="input-group-text" id="basic-addon2">Submit</span> */}
                    <button type="button" className="btn btn-dark">Submit</button>
                  </div>
                  </div>
              <li>Sales@customboxcity.com</li>
              <li>2180 stelzer road Columbus oh 43219</li>
              <li>(+1) 281-231-2946</li>
            </ui>
          </div>
        </div>
        <div className='row'>
            <div className="col-md-12">
              <img class="card-img-top" src={footerImg} alt="Card cap" />
            </div>
        </div>
        <hr className="horizontal-line"/>
        <div className="row">
          <p className="col-sm" style={{textAlign:"center"}}>
            &copy;{new Date().getFullYear()} CBD-Boxes| All rights reserved |
            Terms Of Service | Privacy
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
