import React from "react";
import "./HomeText.css";
import banner from '../Assests/Images/banner.jpg'



const HomeText =() =>{
    return(
        <div className="mainDiv">
            <div className="jumbotron jumboBackground">
                <div className="row">
                    <div className="col-md-12">
                    <h3 className="textHeading"> 
                    Get your Custom Packaging Boxes with logo for Every product at Wholesale Rate
                    </h3>
                    <p className="paragraphText">
                    Custom Box City Packaging is a USA- based manufacturers’ hub for the production of all sorts of Custom Boxes with Logo printed in exquisite style. Your products will no longer have to be wrapped in standard boring boxes. We have the capacity to create custom subscription boxes in all sizes and styles according to clients’ requirement and product specifications. Our printing experts and graphic designers have the skillset to develop the perfect aesthetics to design and produce custom boxes with logo, in a most flattering manner. As a gesture of our good faith, we don’t even charge clients for the customization of design. So anyone of you can come freely and get the hassle-free services. It would be worthwhile for you to experience the best services in the market of the USA. You can also get Custom Pillow Boxes & Custom Cosmetic Boxes with plain and printed designs.
                    </p>
                    </div>
                </div>
                {/* Second Para */}
                <div className="row">
                    <div className="col-md-12">
                    <h3 className="textHeading"> 
                    Get the Eye-Catching Custom Packaging Boxes Wholesale!
                    </h3>
                    <p className="paragraphText">
                    Custom Box City is the best packaging solution you will find in the entire USA where you will get the best quality of custom boxes wholesale. Let’s not waste your time and hit our chat support to book your packaging order now! Because we are the best! So if you need custom boxes then reach us to do no matter what is the size, shape or design we are all set to give you the best services in the USA.
                    </p>
                    </div>
                </div>
                {/* Third paragraph */}
                <div className="row">
                    <div className="col-md-12">
                    <h3 className="textHeading"> 
                    Our vision, Your Success!
                    </h3>
                    <p className="paragraphText" >
                    We at Custom Box City, aim to win customers by offering quality box manufacturing services in a budget-smart way. We are 100% committed to the success of our esteemed clients as we deem it to be our own. Our vision is not to earn profits but admiration, which is why we enable our customers to take full advantage of our affordable pricing and quality packaging boxes service delivery. Our advanced offset, digital & CMYK/ PMS printing technique can you get the attention of major customer targets.
                    </p>
                    </div>
                </div>
                {/* Fourth paragraph */}
                <div className="row">
                    <div className="col-md-12">
                    <h3 className="textHeading"> 
                    Need Custom Boxes Wholesale?
                    </h3>
                    <p className="paragraphText">
                    You have arrived at the right place! You will find the best custom boxes wholesale here at Custom Box City. We know your desires for the product of your packaging. We are the best to bring the best physical shape of your idea. Just give us any artwork, shape, size, or just an idea. We will do it for you without any hassle. So let us do our best performance for your product. Our work will speak in the sales sheet of your business. Get your custom packaging boxes wholesale now to grab more customers for your business. We can make you the best company in the market.
                    </p>
                    </div>
                </div>
                {/* Fifth paragraph */}
                <div className="row">
                    <div className="col-md-12">
                    <h3 className="textHeading"> 
                    We offer Splendid Productivity at the Wholesale Rate!
                    </h3>
                    <p className="paragraphText">
                    Custom Box City is an all-equipped box printing company and production house that’s always ready to produce unique custom boxes for all kinds of consumer products, mass-produced by various businesses. Already, we are earning a name in USA printing market for our Boxes’ unique designs, sturdy built and shipment services. We deliver regular custom printed boxes but we also excel at seasonal packaging services with added festive décor on Personalized Boxes. For inspired & trustworthy Custom Printed Packaging Boxes wholesale manufacturing, contact us NOW (510) 500 9533 or send us your query sales@oxopackaging.com.
                    </p>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-12 bgimg">
                     {/* <img className="d-block w-100" src={banner} alt="First slide" /> */}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default HomeText;