import React from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from './components/Footer'
import Slider from "./components/Slider";
import Card from './components/Card';
import MainBanner  from "./components/MainBanner";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import LoginForm  from "./LoginForm/LoginForm";
import Documentation from "./pages/Documentation";
import Home from './pages/Home'
import CardboardBoxes from "./pages/CardboardBoxes";
import CorrugatedBoxes from "./pages/CorrugatedBoxes";
import KraftBoxes from "./pages/KraftBoxes";
import CustomMailerBoxes from "./pages/CustomMailerBoxes";
import TinctureBox from "./pages/TinctureBox";
import VapeBox from "./pages/VapeBox";
import LipBalm from "./pages/LipBalm";
import PillowBoxes from "./pages/PillowBoxes";
import GableBoxes from "./pages/GableBoxes";
import HairExtension from "./pages/HairExtension";
import CosmeticBoxes from "./pages/CosmeticBoxes";
import HealthBoxes from "./pages/HealthBoxes";
import PersonalCare from "./pages/PersonalCare";
import PharmaBox from "./pages/Pharmabox";
import HairExtensions from "./pages/HairExtensions";
import CandleBox from "./pages/CandleBox";
import SoapBox from "./pages/SoapBox";
import CigaretteBox from "./pages/CigaretteBox";
import PillowBox from "./pages/PillowBox";
import ELiquidBox from "./pages/ELiquidBox";
import RigidBoxes from "./pages/RigidBoxes";
import CustomVapeBox from "./pages/CustomVapeBox";
import Hempbox from "./pages/Hempbox";
import BakeryBox from "./pages/BakeryBox";
import BeverageBox from "./pages/BeverageBox";
import FoodBox from "./pages/FoodBox";
import ChoclateBoxes from "./pages/ChoclateBoxes";
import CerealBox from "./pages/CerealBox";
import PizzaBox from "./pages/PizzaBox";
function App() {
  return (
    <div className="page-container">
      <Header/>
      <main>
        <Route exact path='/'> <Home/> </Route> 
        <Route exact path='/Home'> <Home/> </Route> 
        <Route exact path='/documentation'> <Documentation/> </Route> 
        <Route exact path='/cardboardBoxes'> <CardboardBoxes/> </Route> 
        <Route exact path='/corrugatedBoxes'> <CorrugatedBoxes/> </Route> 
        <Route exact path='/KraftBoxes'> <KraftBoxes/> </Route> 
        <Route exact path='/CustomMailerBoxes'> <CustomMailerBoxes/> </Route> 
        <Route exact path='/TinctureBox'> <TinctureBox/> </Route> 
        <Route exact path='/VapeBox'> <VapeBox/> </Route> 
        <Route exact path='/LipBalm'> <LipBalm/> </Route> 
        <Route exact path='/PillowBoxes'> <PillowBoxes/> </Route> 
        <Route exact path='/GableBoxes'> <GableBoxes/> </Route> 
        <Route exact path='/HairExtension'> <HairExtension/> </Route> 
        <Route exact path='/CosmeticBoxes'> <CosmeticBoxes/> </Route> 
        <Route exact path='/HealthBoxes'> <HealthBoxes/> </Route> 
        <Route exact path='/PersonalCare'> <PersonalCare/> </Route> 
        <Route exact path='/PharmaBox'> <PharmaBox/> </Route> 
        <Route exact path='/HairExtensions'> <HairExtensions/> </Route> 
        <Route exact path='/CandleBox'> <CandleBox/> </Route> 
        <Route exact path='/SoapBox'> <SoapBox/> </Route> 
        <Route exact path='/CigaretteBox'> <CigaretteBox/> </Route> 
        <Route exact path='/PillowBox'> <PillowBox/> </Route> 
        <Route exact path='/ELiquidBox'> <ELiquidBox/> </Route> 
        <Route exact path='/RigidBoxes'> <RigidBoxes/> </Route> 
        <Route exact path='/CustomVapeBox'> <CustomVapeBox/> </Route> 
        <Route exact path='/Hempbox'> <Hempbox/> </Route> 
        <Route exact path='/BakeryBox'> <BakeryBox/> </Route> 
        <Route exact path='/BeverageBox'> <BeverageBox/> </Route> 
        <Route exact path='/FoodBox'> <FoodBox/> </Route> 
        <Route exact path='/ChoclateBoxes'> <ChoclateBoxes/> </Route> 
        <Route exact path='/CerealBox'> <CerealBox/> </Route> 
        <Route exact path='/PizzaBox'> <PizzaBox/> </Route> 
        <Route exact path='/Login'> <LoginForm/> </Route> 
        </main>
        <Footer/>
    </div>
  );
}

export default App;
