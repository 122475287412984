import React from "react";
import "./KraftBoxes.css";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import box1 from '../Assests/Images/CustomMailerBoxes/corrugated-mailer-box.png'
import box2 from '../Assests/Images/CustomMailerBoxes/custom-colored-shiping-corrugated-boxes.png'
import box3 from '../Assests/Images/CustomMailerBoxes/custom-decorative-mailer-boxes.png'
import box4 from '../Assests/Images/CustomMailerBoxes/Customized-corrugated-boxes.png'
import box5 from '../Assests/Images/CustomMailerBoxes/custom-kraft-logo-shipping-boxes.png'
import box6 from '../Assests/Images/CustomMailerBoxes/custom-logo-mailing-corrugated-boxes.png'
import box7 from '../Assests/Images/CustomMailerBoxes/custom-mailer-rigid-boxes.png'
import box8 from '../Assests/Images/CustomMailerBoxes/custom-monthly-box-subscription.png'
import box9 from '../Assests/Images/CustomMailerBoxes/kraft-mailing-box.png'

// import box10 from '../Assests/Images/KraftBoxes/kraft-gift-packaging-boxes.png'
// import box11 from '../Assests/Images/KraftBoxes/kraft-lipstick-box.png'
// import box12 from '../Assests/Images/KraftBoxes/kraft-mailing-box.png'
// import box13 from '../Assests/Images/KraftBoxes/kraft-paper-pillow-gift-boxes-oxopackaging.png'
// import box14 from '../Assests/Images/KraftBoxes/kraft-sandwich-box.png'
// import box15 from '../Assests/Images/KraftBoxes/kraftsoap2.png'
// import box16 from '../Assests/Images/KraftBoxes/kraft-tea-box.png'
// import box17 from '../Assests/Images/KraftBoxes/kraft-tuck-top-box.png'
// import box18 from '../Assests/Images/KraftBoxes/Kraftwhite2.png'
// import box19 from '../Assests/Images/KraftBoxes/kraftwindowbags2.png'
// import box20 from '../Assests/Images/KraftBoxes/kraft-window-box1.png'
// import box21 from '../Assests/Images/KraftBoxes/window-kraft-boxes.png'
import bannerImg from '../Assests/Images/final-gif.gif';

const CustomMailerBoxes=() =>{
    return(
        <div className="mainDiv">
        <div className="jumbotron">
        <h3> Custom Mailer Boxes </h3>
        <img class="card-img-top" src={bannerImg} alt="Card cap"/>
        </div>
    
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box1} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Corrugated Mailer Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box2} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Colored Shipping Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box3} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Decoration Mailer Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card formCard" style={{width: '18rem'}}>
    <div> <h4 className="customQuoteheading"> Get Custom Quote </h4> </div>
    <div className="card-body formCard">
    <form>
        <div className="form-row">
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="lenght" placeholder="Lenght"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="width" placeholder="width"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="depth" placeholder="depth"/>
            </div>
            <div className="form-group col-md-3">
                <select id="inputInches" class="form-control" placeholder="Inch">
                    <option >cm</option>
                    <option>mm</option>
                    <option>inch</option>
                </select>
            </div>
        </div>
        <div className="form-group">
        <label for="inputAddress2">Product Name</label>
        <select id="inputInches" class="form-control">
                    <option>Auto-lock Boxes</option>
                    <option>Baby product boxes</option>
                    <option>Bakery Boxes</option>
                    <option>Ballot Boxes</option>
                    <option>Bandage Boxes</option>
                    <option>Belt Boxes</option>
                    <option>Black Gable Boxes</option>
                    <option>Book end Boxes</option>
                    <option>Bottle Boxes</option>
                    <option>Bottle Neckers</option>
                    <option>Buisness Card Boxes</option>
                    <option>Cake Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Candy Apple Boxes</option>
                    <option>Candy Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Cardboard Boxes</option>
                    <option>Cardboard Dispenser Boxes</option>
                    <option>Cereal Boxes</option>
                    <option>Choclate Boxes</option>
                    <option>Christmas Boxes</option>
                    <option>Ciggarette Boxes</option>
                    <option>Cookie Boxes</option>
                    <option>Corrugated Boxes</option>
                    <option>Cosmetic Boxes</option>
                    <option>Cosmetic Display Boxes</option>
                    <option>Cream Boxes</option>
                    <option>Cube Boxes</option>
                    <option>Cupcake Boxes</option>
                    <option>Custom Bath Bomb Boxes</option>
                    <option>Custom Electronic Ciggarette Boxes</option>
                    <option>Display Boxes</option>
                    <option>Donut Boxes</option>
                    <option>E-Liquid Boxes</option>
                    <option>Eye Shadow Boxes</option>
                    <option>Eye Liner Boxes</option>
                    <option>Favour Boxes</option>
                    <option>Flap Boxes</option>
                    <option>Foundation Boxes</option>
                    <option>Gable Boxes</option>
                    <option>Game Boxes</option>
                    <option>Gift Boxes</option>
                    <option>Gift Card Boxes</option>
                    <option>Gold Foil Boxes</option>
                    <option>Hair Extension Boxes</option>
                    <option>Hair Spray Boxes</option>
                    <option>Handle Boxes</option>
                    <option>Hanging Tab Boxes</option>
                    <option>Hexagon Boxes</option>
                    <option>Invitation Boxes</option>
                    <option>Kraft Boxes</option>
                    <option>Lip Gloss Boxes</option>
                    <option>lipstick Boxes</option>
                    <option>Lotion Boxes</option>
                    <option>Macaron Boxes</option>
                    <option>Mailer Boxes</option>
                    <option>Makeup Boxes</option>
                    <option>Mascara Boxes</option>
                    <option>Medicine Boxes</option>
                    <option>Muffin Boxes</option>
                    <option>Nail Polish Boxes</option>
                    <option>Ornament Boxes</option>
                    <option>Paper Boxes</option>
                    <option>Paper Cup Boxes</option>
                    <option>Pastry Boxes</option>
                    <option>Perfume Boxes</option>
                    <option>Pie Boxes</option>
                    <option>Pillow Boxes</option>
                    <option>Pizza Boxes</option>
                    <option>Playing Card Boxes</option>
                    <option>Popcorn Boxes</option>
                    <option>Product Boxes</option>
                    <option>Pyramid Boxes</option>
                    <option>Retail Boxes</option>
                    <option>Rigid Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Snack Boxes</option>
                    <option>Soap Boxes</option>
                    <option>Software Boxes</option>
                    <option>Tea Boxes</option>
                    <option>Tie Boxes</option>
                    <option>Toy Boxes</option>
                    <option>USB Boxes</option>
                    <option>Window Boxes</option>
                </select>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
            <label for="inputColor">Color:</label>
            <select id="inputState" class="form-control">
                <option selected>1 Color </option>
                <option>2 Color</option>
                <option>3 Color</option>
                <option>4 Color</option>
                <option>4/1 Color</option>
                <option>4/2 Color</option>
                <option>4/3 Color</option>
                <option>4/4 Color</option>
            </select>
        </div>
    <div class="form-group col-md-6">
        <label for="inputQuantity">Quantity</label>
        <input type="number" class="form-control" id="quantity"/>
    </div>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="Name"/>
    </div>
    <div className="form-row formRow">
    <input type="email" class="form-control" placeholder="Email"/>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="tel"/>
    </div>
        <button type="submit" class="btn btn-primary formBtn">Submit</button>
    </form>
        <hr/>
        </div>
    </div>
    </div>
    </div>
    {/* Second Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box4} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Customized Corrugated Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box5} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Kraft Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box6} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Logo Mailing Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Third ROw */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box7} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Mailer Grid Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box8} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Monthly Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box9} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Kraft Mailing Boxes </h5>
    </div>
    </div>
    </div>
    </div>
   
  
    <div className="row">
    <div className="col-md-12">
    <h3 className="secondHeading"> Custom Mailer Boxes</h3>
    <p className="secondPara"> We facilitate your business by bringing you innovative printed boxes with extraordinary design printing. </p>
    <div className="jumbotron jumboBackground">
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Why send plain boxes to your customers when you can wow them with custom mailer boxes?
    </h3>
    <p className="paragraphText">
    Custom mailer boxes are extremely popular these days of online commerce. You can reach your customers in a new way with these handy and grand looking custom mailer boxes that come in all shapes and sizes. You can use these mailer boxes to offer your customers monthly subscriptions of your routine products or simply use them to WOW your customers on the first try. They are very good for making impressive first impressions especially when you are an online e-commerce store operation. Because for the vendors of e-commerce businesses there are many opportunities to showcase their products in real-world, these custom mailer boxes can be their way to ensure the patrons of their professionalism and originality.
    </p>
    </div>
    </div>
    {/* Second Para */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Bring Your Brand to a Vibrant Life with Colorful Customized Mailer Boxes
    </h3>
    <p className="paragraphText">
    These exclusive looking custom mailer boxes are not only available in their original all-natural brown color but also in all other colors of your choice. You can coordinate the theme colors of your brand in the design of your mailer boxes to extend your brand recognition beyond borders. Many brands have used these beautifully printed mailer boxes to promote their ethnicities, cultures as well as their product. How could that work you may ask? The answer is that people love a good unwrapping experience as much as they anticipate to use the product inside. When people get these mailer boxes unexpectedly, they feel as if they received a present. So if your mailer box also looks like a beautiful gift box, your customers are sure to appreciate the thoughtfulness and the effort.
    </p>
    </div>
    </div>
    {/* Third paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Mono-color Custom Mailer Boxes for Sophisticated Brands
    </h3>
    <p className="paragraphText" >
    There are some brands whose theme is more impressive in single color tones, and for those brands, we offer single colored mailer boxes with logo embossed in spot UV technique. We promise you that your customers won’t be able to help but be pleased with these impressionable, simple and unique looking custom mailer boxes. Target demographic is also a related element when you are designing your mailer box as women tend to like the floral, colorful patterns while gents appreciate no-nonsense, simple, straightforward boxes.
    </p>
    </div>
    </div>
    {/* Fourth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Your Partner for the Finest Mailer Boxes Manufacturing
    </h3>
    <p className="paragraphText">
    we produce the finest range of mailer boxes for our clients and their customers. Our experts will stop at nothing but the best box manufacturing process and materials to deliver the best quality services. The very stock material used for these boxes comes from handpicked stock sheets. The material of these custom mailer boxes varies based on the client's preference and nature of the product itself. Lightweight products can be placed in kraft mailer boxes while for weightier products we use corrugated cardboard materials. Custom designed inserts can also be created for products that are fragile or those which are to be sent in numbers. All you need is to tell us what kind of box you are looking for and team OXO can have it ready for your product in no time at all.
    </p>
    </div>
    </div>
    {/* Fifth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Sleek Design of Ear-Lock Mailer Boxes Winning Over Customers World-Wide
    </h3>
    <p className="paragraphText">
    One of the best features of these mailers is its self-locking mechanism. You don't tape them together. You just tuck in the ends (ears) of the box and the product inside is secure. This feature makes this container popular in people as well as brands because these boxes are reusable for more than one reason. They can be customized for multiple uses without losing their efficiency and safekeeping.
    </p>
    </div>
    </div>
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    How to Order Custom Mailer Boxes?
    </h3>
    <p className="paragraphText">
    It’s easy. You reach out to us here: sales@oxopackaging.com You decide the size, graphics, and layout of the box. Our experts will be assisted you throughout to achieve the best results. You can request samples and mockups to ascertain the quality of the materials and the feel of the boxes. What's more, we offer value-added features to convert our customers into our fans.
    Standard & Custom Sizes Available 
    Die cut custom mailer boxes Available 
    Tailored Insets to suit your products 
    Fast turnaround time, Within 6 Business Days 
    Free shipment all across the USA
    Get Custom Corrugated Mailer Boxes with logo with us and enjoy a envoirnment friendly boxes with cheap rates.
    </p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    )
}

export default CustomMailerBoxes;