import React from "react";
import "./CorrugatedBoxes.css";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import box1 from '../Assests/Images/corrugtedBoxes/cake-corrugated-boxes.png'
import box2 from '../Assests/Images/corrugtedBoxes/cardboard-shipping-boxes.png'
import box3 from '../Assests/Images/corrugtedBoxes/corrugated-envelopes.png'
import box4 from '../Assests/Images/corrugtedBoxes/corrugated-mailer-box.png'
import box5 from '../Assests/Images/corrugtedBoxes/custom-boxes-window-corrugated.png'
import box6 from '../Assests/Images/corrugtedBoxes/custom-colored-shiping-corrugated-boxes.png'
import box7 from '../Assests/Images/corrugtedBoxes/custom-corrugated-pizza-boxes.png'
import box8 from '../Assests/Images/corrugtedBoxes/custom-kraft-logo-shipping-boxes.png'
import box9 from '../Assests/Images/corrugtedBoxes/custom-logo-mailing-corrugated-boxes.png'
import box10 from '../Assests/Images/corrugtedBoxes/die-cut-corrugated-boxes.png'
import box11 from '../Assests/Images/corrugtedBoxes/drawer-corrugated-boxes.png'
import box12 from '../Assests/Images/corrugtedBoxes/electronics-corrugated-boxes.png'
import box13 from '../Assests/Images/corrugtedBoxes/-file-storage-corrugated-boxes-.png'
import box14 from '../Assests/Images/corrugtedBoxes/gift-corrugated-boxes.png'
import box15 from '../Assests/Images/corrugtedBoxes/cake-corrugated-boxes.png'
import box16 from '../Assests/Images/corrugtedBoxes/mug-corrugated-boxes.png'
import box17 from '../Assests/Images/corrugtedBoxes/cake-corrugated-boxes.png'
import box18 from '../Assests/Images/corrugtedBoxes/pillow-corrugated-boxes.png'
import box19 from '../Assests/Images/corrugtedBoxes/White_Corrugated_png.png'
import box20 from '../Assests/Images/corrugtedBoxes/textured_corrugated_boxes1.png'
import box21 from '../Assests/Images/corrugtedBoxes/Corrugated_Dispencer.png'
import bannerImg from '../Assests/Images/final-gif.gif';

const CorrugatedBoxes=() =>{
    return(
        <div className="mainDiv">
        <div className="jumbotron">
        <h3> Custom Corrugated Boxes </h3>
        <img class="card-img-top" src={bannerImg} alt="Card cap"/>
        </div>
    
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box1} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Cake Corrugated Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box2} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Cardboard-Shipping Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card" style={{width: '18rem'}}>
    <img className="card-img-top" src={box3} alt="Card cap"/>
    <div className="card-body">
    <hr/>
    <h5>  Corrugated Envelops Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div className="card formCard" style={{width: '18rem'}}>
    <div> <h4 className="customQuoteheading"> Get Custom Quote </h4> </div>
    <div className="card-body formCard">
    <form>
        <div className="form-row">
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="lenght" placeholder="Lenght"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="width" placeholder="width"/>
            </div>
            <div className="form-group col-md-3">
                <input type="number" class="form-control" id="depth" placeholder="depth"/>
            </div>
            <div className="form-group col-md-3">
                <select id="inputInches" class="form-control" placeholder="Inch">
                    <option >cm</option>
                    <option>mm</option>
                    <option>inch</option>
                </select>
            </div>
        </div>
        <div className="form-group">
        <label for="inputAddress2">Product Name</label>
        <select id="inputInches" class="form-control">
                    <option>Auto-lock Boxes</option>
                    <option>Baby product boxes</option>
                    <option>Bakery Boxes</option>
                    <option>Ballot Boxes</option>
                    <option>Bandage Boxes</option>
                    <option>Belt Boxes</option>
                    <option>Black Gable Boxes</option>
                    <option>Book end Boxes</option>
                    <option>Bottle Boxes</option>
                    <option>Bottle Neckers</option>
                    <option>Buisness Card Boxes</option>
                    <option>Cake Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Candy Apple Boxes</option>
                    <option>Candy Boxes</option>
                    <option>Candle Boxes</option>
                    <option>Cardboard Boxes</option>
                    <option>Cardboard Dispenser Boxes</option>
                    <option>Cereal Boxes</option>
                    <option>Choclate Boxes</option>
                    <option>Christmas Boxes</option>
                    <option>Ciggarette Boxes</option>
                    <option>Cookie Boxes</option>
                    <option>Corrugated Boxes</option>
                    <option>Cosmetic Boxes</option>
                    <option>Cosmetic Display Boxes</option>
                    <option>Cream Boxes</option>
                    <option>Cube Boxes</option>
                    <option>Cupcake Boxes</option>
                    <option>Custom Bath Bomb Boxes</option>
                    <option>Custom Electronic Ciggarette Boxes</option>
                    <option>Display Boxes</option>
                    <option>Donut Boxes</option>
                    <option>E-Liquid Boxes</option>
                    <option>Eye Shadow Boxes</option>
                    <option>Eye Liner Boxes</option>
                    <option>Favour Boxes</option>
                    <option>Flap Boxes</option>
                    <option>Foundation Boxes</option>
                    <option>Gable Boxes</option>
                    <option>Game Boxes</option>
                    <option>Gift Boxes</option>
                    <option>Gift Card Boxes</option>
                    <option>Gold Foil Boxes</option>
                    <option>Hair Extension Boxes</option>
                    <option>Hair Spray Boxes</option>
                    <option>Handle Boxes</option>
                    <option>Hanging Tab Boxes</option>
                    <option>Hexagon Boxes</option>
                    <option>Invitation Boxes</option>
                    <option>Kraft Boxes</option>
                    <option>Lip Gloss Boxes</option>
                    <option>lipstick Boxes</option>
                    <option>Lotion Boxes</option>
                    <option>Macaron Boxes</option>
                    <option>Mailer Boxes</option>
                    <option>Makeup Boxes</option>
                    <option>Mascara Boxes</option>
                    <option>Medicine Boxes</option>
                    <option>Muffin Boxes</option>
                    <option>Nail Polish Boxes</option>
                    <option>Ornament Boxes</option>
                    <option>Paper Boxes</option>
                    <option>Paper Cup Boxes</option>
                    <option>Pastry Boxes</option>
                    <option>Perfume Boxes</option>
                    <option>Pie Boxes</option>
                    <option>Pillow Boxes</option>
                    <option>Pizza Boxes</option>
                    <option>Playing Card Boxes</option>
                    <option>Popcorn Boxes</option>
                    <option>Product Boxes</option>
                    <option>Pyramid Boxes</option>
                    <option>Retail Boxes</option>
                    <option>Rigid Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Shirt Boxes</option>
                    <option>Sleeve Boxes</option>
                    <option>Snack Boxes</option>
                    <option>Soap Boxes</option>
                    <option>Software Boxes</option>
                    <option>Tea Boxes</option>
                    <option>Tie Boxes</option>
                    <option>Toy Boxes</option>
                    <option>USB Boxes</option>
                    <option>Window Boxes</option>
                </select>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
            <label for="inputColor">Color:</label>
            <select id="inputState" class="form-control">
                <option selected>1 Color </option>
                <option>2 Color</option>
                <option>3 Color</option>
                <option>4 Color</option>
                <option>4/1 Color</option>
                <option>4/2 Color</option>
                <option>4/3 Color</option>
                <option>4/4 Color</option>
            </select>
        </div>
    <div class="form-group col-md-6">
        <label for="inputQuantity">Quantity</label>
        <input type="number" class="form-control" id="quantity"/>
    </div>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="Name"/>
    </div>
    <div className="form-row formRow">
    <input type="email" class="form-control" placeholder="Email"/>
    </div>
    <div className="form-row formRow">
    <input type="tel"  class="form-control" placeholder="tel"/>
    </div>
        <button type="submit" class="btn btn-primary formBtn">Submit</button>
    </form>
        <hr/>
        </div>
    </div>
    </div>
    </div>
    {/* Second Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box4} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Corrugated Mailer Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box5} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Window Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box6} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Colored Shipping Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Third ROw */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box7} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Corruagted Pizza Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box8} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Kraft Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box9} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Logo Mailing Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Fourth Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box10} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Shipping Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box11} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Die Cutt Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box12} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Drawer Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Fifth Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box13} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Electronics Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box14} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  File Storage Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box15} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Gift Corrugated Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Sixth Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box16} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Mug Corrugated Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box17} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Pillow Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box18} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  White Corrugated Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    {/* Seventh Row */}
    <div className="row centerAlignRow">
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box19} alt="Card cap"/>
    <div class="card-body">
    <h5> Textured Corrugated Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box20} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Corrugated Dispenser Boxes </h5>
    </div>
    </div>
    </div>
    <div className="col-md-3">
    <div class="card" style={{width: '18rem'}}>
    <img class="card-img-top" src={box21} alt="Card cap"/>
    <div class="card-body">
    <hr/>
    <h5>  Custom Mascarra Boxes </h5>
    </div>
    </div>
    </div>
    </div>
    <div className="row">
    <div className="col-md-12">
    <h3 className="secondHeading"> Corrugated Boxes</h3>
    <p className="secondPara"> We facilitate your business by bringing you innovative printed boxes with extraordinary design printing. </p>
    <div className="jumbotron jumboBackground">
    <div className="row">
    {/* <div className="col-md-12">
    <h3 className="textHeading"> 
    Strengthen your Packaging Standards Custom Cardboard Boxes 
    </h3>
    <p className="paragraphText">
    The Custom Cardboard Boxes are the most useful container or every industry! They give extra strength to your product packaging and that’s why manufacturers and retailers are always looking to hunt stylish cardboard boxes. The OXO PACKAGING one of the kingpins of the market of the United States of America and we are proud to be a leading packaging supplier.
    </p>
    </div> */}
    </div>
    {/* Second Para */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Highly Customizable Custom Corrugated Boxes
    </h3>
    <p className="paragraphText">
    Providing highly customizable corrugated boxes, so you can leave an indubitable impression on your patrons with our extraordinarily build custom corrugated boxes. Call our Experts to get the free custom design and free shipping. Get in touch with Oxo packaging for Latest Offers on Packaging Products.
    Today customization is the best tactic used by many businesses and industrialists, with our full customization selection take your packaging to the whole new level. Inspire your customers by providing them eye-catching packaging given them an unforgettable experience. Select from boundless shapes selections like Gable, Pillow and cubed fashioned boxes for your exclusive range of products with your decorated logo.
    </p>
    </div>
    </div>
    {/* Third paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Make a Novelty in your Ordinary Packaging
    </h3>
    <p className="paragraphText" >
    ake full advantage of customization and fully customize your packaging to cater Christmas and Easter needs. We also provide fascinating design options to woo your customers. We offer exceptional shape boxes to provide accommodations for your products in corrugated material as per their size. The experienced designers are always ready to invent a discrete styled box corrugated right according to its form. A distinctively shaped box corrugated appeals viewers and push them to buy. Make a novelty in your ordinary packaging in a corrugated material with OXO Packaging.
    </p>
    </div>
    </div>
    {/* Fourth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Developing Long-lasting relationship with our clients
    </h3>
    <p className="paragraphText">
    Making Custom Corrugated Boxes is our specialty. Our experts ensure quality and quick processing you don’t run out of resources. Our services are reliable and we believe in developing a long-lasting relationship with our clients. We acknowledge the importance of your precious time and provide quick and timely delivery. At OXO Packaging, the best thing is, you will also appreciate our quality designing of corrugated boxes categorically free of cost. In accordance to opting the best designed corrugated packaging, OXO Packaging offers the proficiency of our most knowledgeable designers of the industry. We charge no additional amount for this prowess. Just to make the road of getting the best boxes corrugated smoother and straight.
    </p>
    </div>
    </div>
    {/* Fifth paragraph */}
    <div className="row">
    <div className="col-md-12">
    <h3 className="textHeading"> 
    Deliver the eventual Custom Corrugated Boxes
    </h3>
    <p className="paragraphText">
    Show inventiveness on wholesale printed custom size boxes to make their effort and delivery impactful. We help you goal customers with your logo by familiarizing your top-secret products in custom corrugates boxes to capture the household market. As for shapes, use gable, window, pillow, and cube-shaped for products, retail items or food items in your custom corrugated boxes with logo. We deliver the eventual Custom Mailer Boxes with logo and extravagant design selections. It is an inventive approach to transform the industry by transporting incredible quality of wholesale custom packaging boxes. 
    </p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    )
}

export default CorrugatedBoxes;